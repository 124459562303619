import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { actividarModel } from '../models/actividad.model';
import { obtenerColorAleatorio } from '../../commons/Utilitarios';

export const ACTIVIDAD_PROCESS = '[Actividad] Actividad procesando';
export const ACTIVIDAD_SAVE_SUCCESS = '[Actividad] Actividad guardar exito';
export const ACTIVIDAD_LIST_SUCCESS = '[Actividad] Actividad lista exito';
export const ACTIVIDAD_LIST_FAIL = '[Actividad] Actividad lista error';
export const ACTIVIDAD_SELECTED = '[Actividad] Actividad seleccionado';
export const ACTIVIDAD_CLOSE = '[Actividad] Actividad cerrar modal';
export const ACTIVIDAD_FILTER = '[Actividad] Actividad filtros';
export const ACTIVIDAD_FAIL = '[Actividad] Actividad proceso error';

export const getActividadesAction = () => (dispatch, getState) => {
  let { su, suf, ur, et, fi, ff, page, rows } = getState().actividad;
  let { currentUser } = getState().app;

  dispatch({
    type: ACTIVIDAD_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    suf: currentUser.tr === 'NOBASICO' ? suf : currentUser.iu,
    su,
    ur: currentUser.tr === 'NOBASICO' ? ur : currentUser.us,
    et,
    fi,
    ff,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.TAREA, { params })
    .then((response) => {
      dispatch({
        type: ACTIVIDAD_LIST_SUCCESS,
        payload: response.map((r) => ({
          ...r,
          fa: new Date(r.fa),
          co: obtenerColorAleatorio(),
        })),
      });
    })
    .catch(() => {
      dispatch({
        type: ACTIVIDAD_LIST_FAIL,
      });
    });
};

export const postRegistarActividadesAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ACTIVIDAD_PROCESS,
    });

    let actividad = {
      ie: currentUser.ie,
      iu: item.iu,
      ur: item.ur,
      ua: currentUser.us,
      trs: detalle,
    };

    api
      .post(servicios.TAREA, actividad)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getActividadesAction());
        dispatch({ type: ACTIVIDAD_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ACTIVIDAD_FAIL,
          payload: error.data.errors ? error.data.errors : actividarModel,
        });
      });
  };

export const getSeleccionarActividadAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: ACTIVIDAD_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: ACTIVIDAD_SELECTED,
      payload: actividarModel,
    });
  }
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf, ur, et, fi, ff } = getState().actividad;

  let item = {
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ur: filtros.ur !== undefined ? (filtros.ur === '' ? null : filtros.ur) : ur,
    et: filtros.et !== undefined ? (filtros.et === '' ? null : filtros.et) : et,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
  };

  dispatch({
    type: ACTIVIDAD_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ACTIVIDAD_CLOSE,
  });
};
