import React, { useState } from 'react';
import { connect } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  agregarHeaderAction,
  abrirMenuAction,
} from '../../redux/actions/app.action';

const MenuItem = (props) => {
  const [open, setOpen] = useState(false);
  let href = '#';

  const handleIsMobile = () => {
    const menuContent = document.querySelector('.op-app .op-content-menu');
    if (menuContent.offsetTop === 40 && props.closeMenu === true) {
      props.abrirMenuAction();
    }
  };

  if (props.menu.nf === false) {
    return (
      <li>
        <a
          href={href}
          onClick={() => {
            props.agregarHeaderAction(props.menu);
            handleIsMobile();
          }}
        >
          <span className="icon">
            <Icon>{props.menu.im}</Icon>
          </span>
          <span className="title">{props.menu.op}</span>
        </a>
      </li>
    );
  }

  let hijos = props.list.filter((h) => {
    return h.pp === props.menu.mm;
  });

  hijos = hijos.map((h) => {
    if (h.nf === false) {
      return (
        <li key={h.me}>
          <a
            href={href}
            onClick={() => {
              props.agregarHeaderAction(h);
              handleIsMobile();
            }}
          >
            <span className="icon">
              <Icon>{h.im}</Icon>
            </span>
            <span className="title">{h.op}</span>
          </a>
        </li>
      );
    } else {
      return (
        <MenuItem key={h.me} menu={h} list={props.list} even={!props.even} />
      );
    }
  });

  return (
    <li className={`${open === true ? 'activo' : ''}`}>
      <a href={href} onClick={() => setOpen(!open)}>
        <span className="icon">
          <Icon>{props.menu.im}</Icon>
        </span>
        <span className="title">{props.menu.op}</span>
        <span className="icon">
          <ExpandMoreIcon className="dropdown" />
        </span>
      </a>
      <ul className={`submenu ${props.even === true ? 'even' : 'odd'}`}>
        {hijos}
      </ul>
    </li>
  );
};

const mapToStateToProps = (state) => {
  return {
    closeMenu: state.app.closeMenu,
  };
};

export default connect(mapToStateToProps, {
  agregarHeaderAction,
  abrirMenuAction,
})(MenuItem);
