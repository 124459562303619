import * as fromUsuario from '../actions/usuario.action';
import { usuarioModel } from '../models/usuario.model';

let initialState = {
  usuarios: [],
  roles: [],
  selected: null,
  processing: false,
  errors: usuarioModel,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromUsuario.USUARIO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: usuarioModel,
      };
    case fromUsuario.USUARIO_PROCESS_SUCCESS:
      return {
        ...state,
        processing: false,
        message: action.payload,
        errors: usuarioModel,
      };
    case fromUsuario.USUARIO_PROCESS_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromUsuario.USUARIO_ROLES:
      return {
        ...state,
        processing: false,
        roles: action.payload,
      };
    case fromUsuario.USUARIO_USERS_SUCCESS:
      return {
        ...state,
        usuarios: action.payload,
        processing: false,
      };
    case fromUsuario.USUARIO_USERS_FAIL:
      return {
        ...state,
        usuarios: [],
        processing: false,
      };
    default:
      return state;
  }
}
