import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { patchConfirmarEmpresaAction } from '../redux/actions/usuario.action';

import Loading from '../commons/Loading';

import '../css/pages/confirmar.css';

const ConfirmarRegistro = (props) => {
  const params = new URLSearchParams(props.location.search);
  const em = params.get('em');
  const au = params.get('au');

  useEffect(() => {
    props.patchConfirmarEmpresaAction(em, au);
  }, []);

  return (
    <div className="op-content-confirmar">
      <div className="op-confirmar">
        <h1>Confirmaci&oacute;n de registro</h1>
        <h3>Bienvenido a soluciones OP</h3>
        <p>{props.message}</p>
        <NavLink className="op-boton op-cerrar iniciar" to="/">
          Iniciar sesi&oacute;n
        </NavLink>
        <h5>@ 2021 Soluciones OP - Todos los derechos reservados</h5>
      </div>
      {props.processing && <Loading />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.usuario.message,
    processing: state.usuario.processing,
  };
};

export default connect(mapStateToProps, { patchConfirmarEmpresaAction })(
  ConfirmarRegistro
);
