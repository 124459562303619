import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
} from '../../redux/actions/almacen.action';

import EditIcon from '@material-ui/icons/Edit';

const AlmacenList = ({
  almacenes,
  processing,
  total,
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'al',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => getSeleccionarAlmacenAction(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'na',
      label: 'Nombre',
    },
    {
      key: 'dt',
      label: 'Tipo',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={almacenes}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getAlmacenesAction({ page, pageSize: rows })
        }
        onSearching={() => getAlmacenesAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    almacenes: state.almacen.almacenes,
    processing: state.almacen.processing,
    total: state.almacen.total,
  };
};

export default connect(mapStateToProps, {
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
})(AlmacenList);
