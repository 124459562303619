import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';

import {
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
} from '../../redux/actions/almacen.action';

const AlmacenFilter = ({
  tipoAlmacenes,
  sucursales,
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
}) => {
  const [su, setSu] = useState('');
  const [ca, setCa] = useState('');
  const [st, setSt] = useState('');
  return (
    <Filtros
      onFilter={() => getAlmacenesAction({})}
      onNew={() => getSeleccionarAlmacenAction()}
    >
      <Combo
        name="su"
        label="Sucursal"
        value={su}
        placeholder="Todos"
        tabIndex={20}
        size={3}
        data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
        onChange={({ value }) => {
          setSu(value);
          actualizarFiltros({ su: value });
        }}
      />
      <Combo
        name="ca"
        label="Tipo Almacen"
        value={ca}
        placeholder="Todos"
        tabIndex={21}
        size={3}
        data={tipoAlmacenes.map((i) => ({ value: i.dt, label: i.de }))}
        searcheable
        dropdownTop
        onChange={({ value }) => {
          setCa(value);
          actualizarFiltros({ ta: value });
        }}
      />
      <Combo
        name="st"
        value={st}
        label="Estado del registro"
        placeholder="Todos"
        size={3}
        tabIndex={22}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          setSt(value);
          actualizarFiltros({ st: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoAlmacenes: state.detalle.tipoAlmacenes,
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps, {
  getAlmacenesAction,
  getSeleccionarAlmacenAction,
  actualizarFiltros,
})(AlmacenFilter);
