import React, { useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Bloque from '../../commons/Bloque';
import { confirmacion } from '../../commons/Mensajes';
import { formatoFecha, compararFechas } from '../../commons/Utilitarios';

import { patchCambioUsuarioAction } from '../../redux/actions/usuario.action';

import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import '../../css/pages/cambio.css';

const Cambio = ({
  processing,
  errors,
  currentUser,
  patchCambioUsuarioAction,
}) => {
  const [usuario, setUsuario] = useState({ ac: '', nu: '', nc: '' });
  const [openCurrent, setOpenCurrent] = useState(false);

  const handleChange = (item) => {
    const { name, value } = item;
    setUsuario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="op-component">
      <Bloque titulo="Cambio de contrase&ntilde;a">
        <div className="view-cambio">
          {currentUser && currentUser.fr != 0 && (
            <span
              style={{ color: 'red', marginBottom: '10px' }}
            >{`Su contraseña temporal ${
              compararFechas(currentUser.fr) ? 'venció' : 'expira'
            } el ${formatoFecha(currentUser.fr)}`}</span>
          )}
          <Texto
            name="ac"
            value={usuario.ac}
            label="Contrase&ntilde;a actual"
            type={`${openCurrent ? 'text' : 'password'}`}
            tabIndex={10}
            error={errors.ac}
            onChange={handleChange}
            icon={() =>
              openCurrent ? (
                <VisibilityIcon onClick={() => setOpenCurrent(false)} />
              ) : (
                <VisibilityOffIcon onClick={() => setOpenCurrent(true)} />
              )
            }
            restrict="password"
            enabledPaste
          />
          <Texto
            name="nu"
            value={usuario.nu}
            label="Contrase&ntilde;a nueva"
            type="password"
            tabIndex={11}
            error={errors.nu}
            onChange={handleChange}
          />
          <Texto
            name="nc"
            value={usuario.nc}
            label="Confirmar contrase&ntilde;a nueva"
            type="password"
            tabIndex={12}
            error={errors.nc}
            onChange={handleChange}
          />
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            onClick={() =>
              confirmacion('¿Esta seguro?', `De cambiar su contraseña`)
                .then(() => patchCambioUsuarioAction(usuario))
                .catch(() => {})
            }
          >
            Cambiar
          </Boton>
          <h3>Reglas de una contrase&ntilde;a segura</h3>
          <span>1.- Debe contener al menos un n&uacute;mero.</span>
          <span>
            2.- Debe contener letras may&uacute;sculas y min&uacute;sculas.
          </span>
          <span>
            3.- Debe tener un tama&ntilde;o m&iacute;nimo de 8 y m&aacute;ximo
            de 16 d&iacute;gitos.
          </span>
          <span>
            4.- Debe contener al menos uno de estos caracteres: @#$%&+=
          </span>
        </div>
      </Bloque>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.app.currentUser,
    processing: state.usuario.processing,
    errors: state.usuario.errors,
  };
};

export default connect(mapStateToProps, {
  patchCambioUsuarioAction,
})(Cambio);
