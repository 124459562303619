import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';

import { api } from '../../redux/axios/api';

import Loading from '../../commons/Loading';

import '../../css/commons/modal.css';

const Report = ({
  url,
  params,
  onClose,
  downloadable,
  filename,
  extension,
}) => {
  const [processing, setProcessing] = useState(true);
  const [urlReport, setUrlReport] = useState('');
  const modalRef = useRef(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    api
      .get(`${url}`, { responseType: 'blob', params })
      .then((response) => {
        setProcessing(false);
        let fileUrl = window.URL.createObjectURL(new Blob([response]));
        setUrlReport(fileUrl);
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: error.status === 500 ? 'error' : 'warning',
          title: 'Error al generar reporte',
        });
        onClose();
      });
    modalRef.current.focus();
  }, []);

  return (
    <div className="op-content-modal" ref={modalRef}>
      <div
        className={`op-modal ${
          downloadable ? 'op-small' : 'op-medium'
        } content-imagen`}
      >
        <div className="op-modal-header">
          <div className="op-modal-close" onClick={() => onClose()}></div>
        </div>
        <div className="op-modal-body">
          {downloadable ? (
            <a
              href={urlReport}
              download={`${filename}.${extension}`}
              onClick={() => onClose()}
            >
              Descargar reporte
            </a>
          ) : (
            <>
              {urlReport && (
                <>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.js">
                    <Viewer
                      fileUrl={urlReport}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </>
              )}
            </>
          )}
        </div>
        {processing && <Loading />}
      </div>
    </div>
  );
};

Report.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  downloadable: PropTypes.bool,
  filename: PropTypes.string,
  extension: PropTypes.string,
};

Report.defaultProps = {
  downloadable: false,
  filename: 'reporte',
  extension: 'xlsx',
};

export default Report;
