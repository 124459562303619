import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import { confirmacion } from '../../commons/Mensajes';

import {
  postRegistarActividadesAction,
  closeModal,
} from '../../redux/actions/actividad.action';
import { getUsuariosAction } from '../../redux/actions/usuario.action';

import { actividarModel } from '../../redux/models/actividad.model';
import Calendario from '../../commons/Calendario';

import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

const ActividadRegister = () => {
  const [tareas, setTareas] = useState([]);
  const [actividad, setActividad] = useState(actividarModel);
  const [open, setOpen] = useState(false);
  const [{ fecha, detalle, index }, setDetalle] = useState({
    fecha: null,
    detalle: null,
    index: -1,
  });
  const dispatch = useDispatch();

  const { processing, errors } = useSelector((state) => state.actividad);
  const { sucursales } = useSelector((state) => state.sucursal);
  const { usuarios } = useSelector((state) => state.usuario);
  const processingUser = useSelector((state) => state.usuario.processing);

  const handleChange = (item) => {
    const { name, value } = item;
    setActividad((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeDetail = (item) => {
    const { name, value } = item;
    setDetalle((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Registrar actividades`}
        processing={processing}
        loading="tasks"
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={22}
              style={{ '--button-color': '#1ab394' }}
              onClick={() => setOpen(true)}
            >
              Agregar actividad
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion('¿Desea guardar?', `Las actividades asignadas`)
                  .then(() =>
                    dispatch(postRegistarActividadesAction(actividad, tareas))
                  )
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => dispatch(closeModal()))
            .catch(() => {})
        }
      >
        <Combo
          name="iu"
          label="Sucursal"
          value={actividad.iu}
          size={2}
          tabIndex={10}
          data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
          onChange={(item) => {
            handleChange(item);
            dispatch(getUsuariosAction(item.value));
            setTareas([]);
          }}
        />
        <Combo
          name="ur"
          value={actividad.ur}
          disabled={processingUser}
          label="Responsable"
          size={2}
          data={usuarios.map((x) => ({ value: x.us, label: x.np }))}
          tabIndex={11}
          error={errors.ur}
          onChange={(item) => {
            handleChange(item);
            setTareas([]);
          }}
          info={'Usuario responsable de las actividades'}
        />
        <div className="op-form-group op-col-1 actividades">
          {tareas.map((t, i) => (
            <div key={i} className="info">
              <Calendario
                name="fa"
                showMini
                showTime
                value={t.fa}
                onSelected={() => {}}
              />
              <span className="texto">{t.dt}</span>
              <div
                className="action close"
                title="eliminar"
                onClick={() => {
                  let currents = tareas.filter((d, index) => index !== i);
                  setTareas(currents);
                }}
              ></div>
              <div
                className="action edit"
                title="editar"
                onClick={() => {
                  handleChangeDetail({ name: 'fecha', value: t.fa });
                  handleChangeDetail({ name: 'detalle', value: t.dt });
                  handleChangeDetail({ name: 'index', value: i });
                  setOpen(true);
                }}
              ></div>
            </div>
          ))}
        </div>
      </Modal>
      {open && (
        <Modal
          title="Agregar actividad"
          onClose={() => setOpen(false)}
          size="small"
          onActions={() => (
            <Boton
              className="op-grabar"
              onClick={() => {
                if (index >= 0) {
                  setTareas(
                    tareas.map((x, i) =>
                      i === index ? { ...x, fa: fecha, dt: detalle } : x
                    )
                  );
                } else {
                  setTareas([...tareas, { fa: fecha, dt: detalle }]);
                }
                handleChangeDetail({ name: 'fecha', value: null });
                handleChangeDetail({ name: 'detalle', value: null });
                handleChangeDetail({ name: 'index', value: -1 });
                setOpen(false);
              }}
            >
              Enviar
            </Boton>
          )}
        >
          <Calendario
            name="fecha"
            value={fecha}
            tabIndex={30}
            label="Fecha"
            showTime
            onSelected={handleChangeDetail}
          />
          <Texto
            name="detalle"
            value={detalle}
            tabIndex={31}
            label="Descripci&oacute;n"
            onChange={handleChangeDetail}
          />
        </Modal>
      )}
    </>
  );
};

export default ActividadRegister;
