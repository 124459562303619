import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { detalleModel } from '../models/detalle.model';

export const DETALLE_PROCESS = '[Detalle] Detalle procesando';
export const DETALLE_FAIL = '[Detalle] Detalle proceso fail';
export const DETALLE_SAVE_SUCCESS = '[Detalle] Detalle proceso exito';
export const DETALLE_LIST_SUCCESS = '[Detalle] Detalle lista exito';
export const DETALLE_LIST_FAIL = '[Detalle] Detalle lista error';
export const DETALLE_SELECTED = '[Detalle] Detalle seleccionado';
export const DETALLE_CLOSE = '[Detalle] Detalle cerrar modal';
export const DETALLE_FILTER = '[Detalle] Detalle filtros';
export const DETALLE_TIPOALMACEN = '[Detalle] Detalle lista tipo almacen';
export const DETALLE_UNIDADMEDIDA =
  '[Detalle] Detalle lista tipo unidad medida';
export const DETALLE_ESTADOARCHIVOS =
  '[Detalle] Detalle lista estado de archivos';

export const getDetallesAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { ca, st, search } = getState().detalle;

    dispatch({
      type: DETALLE_PROCESS,
    });

    const params = {
      ca,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.DETALLE, { params })
      .then((response) => {
        dispatch({
          type: DETALLE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: DETALLE_LIST_FAIL,
        });
      });
  };

export const getDetallesPorCabeceraAction =
  ({ ca, page, pageSize }) =>
  (dispatch) => {
    const params = {
      page,
      pageSize,
    };

    api
      .get(`${servicios.DETALLE}/${ca}/cabecera`, { params })
      .then((response) => {
        switch (ca) {
          case 'TIPOALMACEN':
            dispatch({
              type: DETALLE_TIPOALMACEN,
              payload: response,
            });
            return;
          case 'TIPOUNIDADMEDIDA':
            dispatch({
              type: DETALLE_UNIDADMEDIDA,
              payload: response,
            });
            return;
          case 'ESTADOARCHIVO':
            dispatch({
              type: DETALLE_ESTADOARCHIVOS,
              payload: response,
            });
            return;
          default:
            return;
        }
      });
  };

export const getSeleccionarDetalleAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: DETALLE_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: DETALLE_SELECTED,
      payload: detalleModel,
    });
  }
};

export const postDetalleAction = (item) => (dispatch) => {
  dispatch({
    type: DETALLE_PROCESS,
  });

  api
    .post(servicios.DETALLE, item)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getDetallesAction({}));
      dispatch({ type: DETALLE_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: DETALLE_FAIL,
        payload: error.data.errors ? error.data.errors : detalleModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { ca, st, search } = getState().detalle;

  let item = {
    ca: filtros.ca !== undefined ? (filtros.ca === '' ? null : filtros.ca) : ca,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: DETALLE_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: DETALLE_CLOSE,
  });
};
