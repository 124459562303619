import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import ArchivoFilter from './ArchivoFilter';
import ArchivoList from './ArchivoList';
import ArchivoRegister from './ArchivoRegister';
import ArchivoView from './ArchivoView';

import { getArchivosAction } from '../../redux/actions/archivo.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

const Archivo = ({ openModal, openModalView }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArchivosAction());
    dispatch(getSucursalesAction({}));
    dispatch(getDetallesPorCabeceraAction({ ca: 'ESTADOARCHIVO' }));
  }, []);
  return (
    <div className="op-component">
      <ArchivoList />
      <ArchivoFilter />
      {openModal && <ArchivoRegister />}
      {openModalView && <ArchivoView />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.archivo.openModal,
    openModalView: state.archivo.openModalView,
  };
};

export default connect(mapStateToProps)(Archivo);
