import * as fromUbigueo from '../actions/ubigueo.action';

let initialState = {
  departamentos: [],
  provincias: [],
  distritos: [],
  processing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromUbigueo.UBIGUEO_PROCESS:
      return {
        ...state,
        processing: true,
      };
    case fromUbigueo.UBIGUEO_LIST_DEPARTAMENTOS:
      return {
        ...state,
        processing: false,
        departamentos: action.payload,
        provincias: [],
        distritos: [],
      };
    case fromUbigueo.UBIGUEO_LIST_PROVINCIAS:
      return {
        ...state,
        processing: false,
        provincias: action.payload,
        distritos: [],
      };
    case fromUbigueo.UBIGUEO_LIST_DISTRITOS:
      return {
        ...state,
        processing: false,
        distritos: action.payload,
      };
    default:
      return state;
  }
}
