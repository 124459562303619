import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';

import {
  getArchivosAction,
  getSeleccionarArchivoAction,
  actualizarFiltros,
} from '../../redux/actions/archivo.action';
import { getUsuariosAction } from '../../redux/actions/usuario.action';

const ArchivoFilter = ({
  sucursal,
  sucursales,
  usuarios,
  estadoArchivos,
  responsable,
  inicio,
  fin,
  estado,
  perfil,
}) => {
  const dispatch = useDispatch();

  return (
    <Filtros
      onFilter={() => dispatch(getArchivosAction())}
      onNew={() => dispatch(getSeleccionarArchivoAction())}
    >
      {perfil === 'NOBASICO' && (
        <>
          <Combo
            name="su"
            label="Sucursal"
            value={sucursal}
            placeholder="Todos"
            tabIndex={20}
            size={3}
            data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
            onChange={({ value }) => {
              dispatch(actualizarFiltros({ suf: value }));
              dispatch(getUsuariosAction(value));
            }}
          />
          <Combo
            name="ur"
            value={responsable}
            label="Responsable"
            placeholder="Todos"
            size={3}
            data={usuarios.map((x) => ({ value: x.us, label: x.np }))}
            tabIndex={21}
            onChange={({ value }) => {
              dispatch(actualizarFiltros({ ur: value }));
            }}
          />
        </>
      )}
      <Combo
        name="ea"
        label="Estado archivo"
        value={estado}
        placeholder="Todos"
        tabIndex={22}
        size={3}
        data={estadoArchivos.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          dispatch(actualizarFiltros({ ea: value }));
        }}
      />
      <Texto
        name="fi"
        value={inicio}
        label="Fecha de inicio"
        tabIndex={23}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch(actualizarFiltros({ fi: value }));
        }}
      />
      <Texto
        name="ff"
        value={fin}
        label="Fecha fin"
        tabIndex={24}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch(actualizarFiltros({ ff: value }));
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    usuarios: state.usuario.usuarios,
    estadoArchivos: state.detalle.estadoArchivos,
    sucursal: state.archivo.suf,
    responsable: state.archivo.ur,
    inicio: state.archivo.fi,
    fin: state.archivo.ff,
    estado: state.archivo.ea,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(ArchivoFilter);
