import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';

import Boton from './Boton';
import Loading from './Loading';

import CloseIcon from '@material-ui/icons/Close';

import '../css/commons/modal.css';

const Modal = (props) => {
  const modalRef = useRef(null);

  useEffect(() => {
    modalRef.current.focus();
  }, []);

  const actionButtons = () => (
    <>
      {props.onActions && props.onActions()}
      <Boton
        disabled={props.processing}
        className="op-cerrar"
        icon={() => <CloseIcon />}
        onClick={() => props.onClose()}
      >
        Cerrar
      </Boton>
    </>
  );

  return (
    <div
      className="op-content-modal"
      ref={modalRef}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          if (props.processing === false && props.closePressEscape === true) {
            props.onClose();
          }
        }
      }}
      tabIndex={0}
    >
      <div className={`op-modal${props.size ? ' op-' + props.size : ''}`}>
        <div className="op-modal-header">
          <span>{props.title}</span>
          <div
            className={`op-modal-close${
              props.processing === true ? ' disabled' : ''
            }`}
            onClick={() => props.onClose()}
          ></div>
        </div>
        <div className="op-modal-body">
          <div className="op-form">{props.children}</div>
        </div>
        <div className="op-modal-footer">{actionButtons()}</div>
        {props.processing && <Loading tipo={props.loading} />}
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: Proptypes.string.isRequired,
  children: Proptypes.any.isRequired,
  onClose: Proptypes.func.isRequired,
  size: Proptypes.oneOf(['small', 'medium', 'large']),
  onActions: Proptypes.func,
  processing: Proptypes.bool,
  closePressEscape: Proptypes.bool,
  loading: Proptypes.string,
};

Modal.defaultProps = {
  processing: false,
  closePressEscape: true,
  loading: 'default',
};

export default Modal;
