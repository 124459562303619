import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';

import {
  getSucursalesAction,
  getSeleccionarSucursalAction,
  actualizarFiltros,
} from '../../redux/actions/sucursal.action';

import EditIcon from '@material-ui/icons/Edit';

const SucursalList = ({
  sucursales,
  processing,
  total,
  getSucursalesAction,
  getSeleccionarSucursalAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'su',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => getSeleccionarSucursalAction(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Nombre',
    },
    {
      key: 'sp',
      align: 'center',
      label: 'Principal',
      content: (item) => (item.sp === 'S' ? 'SI' : 'NO'),
    },
    {
      key: 'de',
      label: 'Estado sucursal',
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={sucursales}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getSucursalesAction({ page, pageSize: rows })
        }
        onSearching={() => getSucursalesAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    processing: state.sucursal.processing,
    total: state.sucursal.total,
  };
};

export default connect(mapStateToProps, {
  getSucursalesAction,
  getSeleccionarSucursalAction,
  actualizarFiltros,
})(SucursalList);
