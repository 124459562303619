import React from 'react';
import { connect } from 'react-redux';

import Boton from '../../commons/Boton';
import { formatoFecha } from '../../commons/Utilitarios';

import { logoutAction } from '../../redux/actions/app.action';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import '../../css/info.css';

const Info = ({ currentUser, open, logoutAction }) => {
  return (
    <div className={`op-content-info${open ? ' open' : ''}`}>
      <h3>Mi cuenta</h3>
      <span>{`Nombres : ${currentUser.np}`}</span>
      <span>{`Empresa : ${currentUser.ne}`}</span>
      <span>{`Sucursal : ${currentUser.ns}`}</span>
      <span>{`Rol : ${currentUser.nr}`}</span>
      <span>{`Último ingreso : ${formatoFecha(currentUser.fu)}`}</span>
      <div className="separador"></div>
      <Boton
        icon={() => <ExitToAppIcon fontSize="small" />}
        onClick={logoutAction}
      >
        Salir
      </Boton>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.app.currentUser,
  };
};

export default connect(mapStateToProps, { logoutAction })(Info);
