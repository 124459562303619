import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getAlmacenesAction } from '../../redux/actions/almacen.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import AlmacenList from './AlmacenList';
import AlmacenFilter from './AlmacenFilter';
import AlmacenItem from './AlmacenItem';

const Almacen = ({
  openModal,
  getAlmacenesAction,
  getDetallesPorCabeceraAction,
  getSucursalesAction,
}) => {
  useEffect(() => {
    getAlmacenesAction({});
    getSucursalesAction({});
    getDetallesPorCabeceraAction({ ca: 'TIPOALMACEN' });
  }, []);

  return (
    <div className="op-component">
      <AlmacenList />
      <AlmacenFilter />
      {openModal && <AlmacenItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.almacen.openModal,
  };
};

export default connect(mapStateToProps, {
  getAlmacenesAction,
  getDetallesPorCabeceraAction,
  getSucursalesAction,
})(Almacen);
