import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  postLoginAction,
  getCaptchaAction,
  putCaptchaAction,
} from '../redux/actions/app.action';
import Texto from '../commons/Texto';
import Boton from '../commons/Boton';
import Loading from '../commons/Loading';

import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import '../css/pages/login.css';

const Login = ({
  processing,
  captcha,
  captchaProcessing,
  error,
  postLoginAction,
  getCaptchaAction,
  putCaptchaAction,
}) => {
  const [user, setUser] = useState({
    us: '',
    pd: '',
    tx: '',
  });

  const [openCurrent, setOpenCurrent] = useState(false);

  useEffect(() => {
    getCaptchaAction();
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    postLoginAction(user);
    handleChange({ name: 'tx', value: null });
  };

  const handleRefreshCaptcha = () => {
    putCaptchaAction(captcha.ct);
  };

  return (
    <div className="op-content-login">
      <div className="op-login">
        <h1>Documenta</h1>
        <Texto
          name="us"
          value={user.us}
          label="Correo"
          tabIndex={1}
          onChange={handleChange}
        ></Texto>
        <Texto
          name="pd"
          value={user.pd}
          label="Contrase&ntilde;a"
          tabIndex={2}
          type={`${openCurrent ? 'text' : 'password'}`}
          onChange={handleChange}
          icon={() =>
            openCurrent ? (
              <VisibilityIcon onClick={() => setOpenCurrent(false)} />
            ) : (
              <VisibilityOffIcon onClick={() => setOpenCurrent(true)} />
            )
          }
          restrict="password"
          enabledPaste
        ></Texto>
        <div className="op-form-group op-captcha">
          {captchaProcessing ? (
            <>
              <div></div>
              <i className="op-loading"></i>
            </>
          ) : (
            <>
              <img
                alt="captcha"
                src={`data:image/jpg;base64,${captcha.img}`}
                style={{ cursor: 'pointer' }}
              ></img>
              <RefreshIcon fontSize="large" onClick={handleRefreshCaptcha} />
            </>
          )}
        </div>
        {captchaProcessing === false && (
          <p>{`La imagen contiene ${captcha.si} caracteres`}</p>
        )}
        <Texto
          label="Escribe los caracteres"
          name="tx"
          value={user.tx}
          tabIndex={3}
          onChange={handleChange}
        />
        <Boton
          className="op-grabar"
          disabled={processing}
          onClick={handleSubmit}
        >
          Inicia sesi&oacute;n
        </Boton>
        {error && <label className="op-error">{error}</label>}
        {processing === true && <Loading />}
        <NavLink className="op-olvido" to="/olvido">
          ¿Olvidaste tu contrase&ntilde;a?
        </NavLink>
        <h5>@ 2021 Soluciones OP - Todos los derechos reservados</h5>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.app.processing,
    error: state.app.error,
    captcha: state.app.captcha,
    captchaProcessing: state.app.captchaProcessing,
  };
};

export default connect(mapStateToProps, {
  postLoginAction,
  getCaptchaAction,
  putCaptchaAction,
})(Login);
