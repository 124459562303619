import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MenuItem from './MenuItem';
import Loading from '../../commons/Loading';

import { getMenuesPermitidosAction } from '../../redux/actions/app.action';

import '../../css/menu.css';

const Menu = ({
  menues,
  closeMenu,
  menuesProcessing,
  getMenuesPermitidosAction,
}) => {
  useEffect(() => {
    getMenuesPermitidosAction();
  }, []);

  let raices = menues.filter((m) => {
    return m.opp;
  });

  let lista = raices.map((m) => {
    return <MenuItem key={m.me} menu={m} list={menues} even={true} />;
  });

  return (
    <div className={`op-content-menu${closeMenu === true ? ' active' : ''}`}>
      {menuesProcessing && <Loading />}
      <ul>{lista}</ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menues: state.app.menues,
    closeMenu: state.app.closeMenu,
    menuesProcessing: state.app.menuesProcessing,
  };
};

export default connect(mapStateToProps, { getMenuesPermitidosAction })(Menu);
