export const actividarModel = {
  ta: null,
  iu: null,
  fa: null,
  ur: null,
  nu: null,
  dt: null,
  fr: null,
  ua: null,
  na: null,
  ds: null,
  ve: null,
};
