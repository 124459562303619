import * as fromSucursal from '../actions/sucursal.action';
import { sucursalModel } from '../models/sucursal.model';

let initialState = {
  sucursales: [],
  es: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
  openModalEdit: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromSucursal.SUCURSAL_PROCESS:
      return {
        ...state,
        processing: true,
        errors: sucursalModel,
      };
    case fromSucursal.SUCURSAL_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        sucursales: action.payload.data,
        total: action.payload.count,
      };
    case fromSucursal.SUCURSAL_LIST_FAIL:
      return {
        ...state,
        processing: false,
        sucursales: [],
        total: 0,
      };
    case fromSucursal.SUCURSAL_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: sucursalModel,
        openModal: false,
        openModalEdit: false,
      };
    case fromSucursal.SUCURSAL_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromSucursal.SUCURSAL_FILTER:
      return {
        ...state,
        es: action.payload.es,
        search: action.payload.search,
      };
    case fromSucursal.SUCURSAL_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: sucursalModel,
        openModalEdit: true,
      };
    case fromSucursal.SUCURSAL_NEW:
      return {
        ...state,
        selected: action.payload,
        errors: sucursalModel,
        openModal: true,
      };
    case fromSucursal.SUCURSAL_CLOSE:
      return {
        ...state,
        processing: false,
        selected: sucursalModel,
        openModal: false,
        openModalEdit: false,
      };
    default:
      return state;
  }
}
