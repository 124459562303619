import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { archivoModel } from '../models/archivo.model';

export const ARCHIVO_PROCESS = '[Archivo] Archivo procesando';
export const ARCHIVO_SAVE_SUCCESS = '[Archivo] Archivo guardar exito';
export const ARCHIVO_LIST_SUCCESS = '[Archivo] Archivo lista exito';
export const ARCHIVO_LIST_FAIL = '[Archivo] Archivo lista error';
export const ARCHIVO_SELECTED = '[Archivo] Archivo seleccionado';
export const ARCHIVO_VIEW = '[Archivo] Archivo view';
export const ARCHIVO_CLOSE = '[Archivo] Archivo cerrar modal';
export const ARCHIVO_FILTER = '[Archivo] Archivo filtros';
export const ARCHIVO_PAGINATE = '[Archivo] Archivo paginado';
export const ARCHIVO_FAIL = '[Archivo] Archivo proceso error';

export const getArchivosAction = () => (dispatch, getState) => {
  let { su, suf, ur, ea, fi, ff, page, rows } = getState().archivo;
  let { currentUser } = getState().app;

  dispatch({
    type: ARCHIVO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    suf,
    su,
    ur: currentUser.tr === 'NOBASICO' ? ur : currentUser.us,
    ea,
    fi,
    ff,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.ARCHIVO, { params })
    .then((response) => {
      dispatch({
        type: ARCHIVO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: ARCHIVO_LIST_FAIL,
      });
    });
};

export const postRegistarArchivoAction =
  (item, documento) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ARCHIVO_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', documento);
    if (item.ar) {
      form_data.append('ar', item.ar);
    }
    form_data.append('ie', currentUser.ie);
    form_data.append('su', currentUser.is);
    form_data.append('ur', currentUser.us);
    form_data.append('ta', item.ta);

    api
      .post(`${servicios.ARCHIVO}`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getArchivosAction());
        dispatch(paginado(0));
        dispatch({ type: ARCHIVO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ARCHIVO_FAIL,
          payload: error.data.errors ? error.data.errors : archivoModel,
        });
      });
  };

export const patchDesaprobarArchivoAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ARCHIVO_PROCESS,
    });

    let archivo = {
      ar: item.ar,
      ie: currentUser.ie,
      ua: currentUser.us,
      mt: motivo,
    };

    api
      .patch(`${servicios.ARCHIVO}/desaprobar`, archivo)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getArchivosAction({}));
        dispatch(paginado(0));
        dispatch({ type: ARCHIVO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ARCHIVO_FAIL,
          payload: error.data.errors ? error.data.errors : archivoModel,
        });
      });
  };

export const patchRectificarArchivoAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ARCHIVO_PROCESS,
    });

    let archivo = {
      ar: item.ar,
      ie: currentUser.ie,
      ua: currentUser.us,
      mt: motivo,
    };

    api
      .patch(`${servicios.ARCHIVO}/rectificar`, archivo)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getArchivosAction({}));
        dispatch(paginado(0));
        dispatch({ type: ARCHIVO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ARCHIVO_FAIL,
          payload: error.data.errors ? error.data.errors : archivoModel,
        });
      });
  };

export const patchAprobarArchivoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ARCHIVO_PROCESS,
  });

  let archivo = {
    ar: item.ar,
    ie: currentUser.ie,
    ua: currentUser.us,
  };

  api
    .patch(`${servicios.ARCHIVO}/aprobar`, archivo)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getArchivosAction({}));
      dispatch(paginado(0));
      dispatch({ type: ARCHIVO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: ARCHIVO_FAIL,
        payload: error.data.errors ? error.data.errors : archivoModel,
      });
    });
};

export const patchEditarArchivoAction =
  (item, co, documento) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ARCHIVO_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', documento);
    form_data.append('ar', item.ar);
    form_data.append('ie', currentUser.ie);
    form_data.append('co', co);

    api
      .patch(`${servicios.ARCHIVO}/editar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getArchivosAction());
        dispatch(paginado(0));
        dispatch({ type: ARCHIVO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ARCHIVO_FAIL,
          payload: error.data.errors ? error.data.errors : archivoModel,
        });
      });
  };

export const patchDarBajaArchivoAction = (item, co) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ARCHIVO_PROCESS,
  });

  let archivo = {
    ar: item.ar,
    ie: currentUser.ie,
    co: co,
  };

  api
    .patch(`${servicios.ARCHIVO}/baja`, archivo)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getArchivosAction({}));
      dispatch(paginado(0));
      dispatch({ type: ARCHIVO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: ARCHIVO_FAIL,
        payload: error.data.errors ? error.data.errors : archivoModel,
      });
    });
};

export const getSeleccionarArchivoAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: ARCHIVO_VIEW,
      payload: item,
    });
  } else {
    dispatch({
      type: ARCHIVO_SELECTED,
      payload: archivoModel,
    });
  }
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf, ur, ea, fi, ff } = getState().archivo;

  let item = {
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ur: filtros.ur !== undefined ? (filtros.ur === '' ? null : filtros.ur) : ur,
    ea: filtros.ea !== undefined ? (filtros.ea === '' ? null : filtros.ea) : ea,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
  };

  dispatch({
    type: ARCHIVO_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: ARCHIVO_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ARCHIVO_CLOSE,
  });
};
