import * as fromAlmacen from '../actions/almacen.action';
import { almacenModel } from '../models/almacen.model';

let initialState = {
  almacenes: [],
  iu: null,
  su: null,
  ta: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromAlmacen.ALMACEN_PROCESS:
      return {
        ...state,
        processing: true,
        errors: almacenModel,
      };
    case fromAlmacen.ALMACEN_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        almacenes: action.payload.data,
        total: action.payload.count,
      };
    case fromAlmacen.ALMACEN_LIST_FAIL:
      return {
        ...state,
        processing: false,
        almacenes: [],
        total: 0,
      };
    case fromAlmacen.ALMACEN_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: almacenModel,
        openModal: true,
      };
    case fromAlmacen.ALMACEN_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: almacenModel,
      };
    case fromAlmacen.ALMACEN_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromAlmacen.ALMACEN_FILTER:
      return {
        ...state,
        iu: action.payload.iu,
        su: action.payload.su,
        ta: action.payload.ta,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromAlmacen.ALMACEN_CLOSE:
      return {
        ...state,
        errors: almacenModel,
        openModal: false,
      };
    default:
      return state;
  }
}
