import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';

import {
  actualizarFiltros,
  getDetallesAction,
  getSeleccionarDetalleAction,
} from '../../redux/actions/detalle.action';

const DetalleFilter = ({
  cabeceras,
  actualizarFiltros,
  getDetallesAction,
  getSeleccionarDetalleAction,
}) => {
  const [st, setSt] = useState('');
  const [ca, setCa] = useState('');
  return (
    <Filtros
      onFilter={() => getDetallesAction({})}
      onNew={() => getSeleccionarDetalleAction()}
    >
      <Combo
        name="ca"
        label="Cabecera"
        value={ca}
        placeholder="Todos"
        tabIndex={20}
        size={2}
        data={cabeceras.map((i) => ({ value: i.ca, label: i.de }))}
        searcheable
        dropdownTop
        onChange={({ value }) => {
          setCa(value);
          actualizarFiltros({ ca: value });
        }}
      />
      <Combo
        name="st"
        value={st}
        label="Estado del registro"
        placeholder="Todos"
        size={2}
        tabIndex={22}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          setSt(value);
          actualizarFiltros({ st: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    cabeceras: state.cabecera.cabeceras,
  };
};

export default connect(mapStateToProps, {
  actualizarFiltros,
  getDetallesAction,
  getSeleccionarDetalleAction,
})(DetalleFilter);
