import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getCabecerasAction,
  getSeleccionarCabeceraAction,
} from '../../redux/actions/cabecera.action';

const CabeceraFilter = ({
  getSeleccionarCabeceraAction,
  getCabecerasAction,
}) => {
  return (
    <Filtros
      onFilter={() => getCabecerasAction({})}
      onNew={() => getSeleccionarCabeceraAction()}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getCabecerasAction,
  getSeleccionarCabeceraAction,
})(CabeceraFilter);
