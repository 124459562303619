import { api } from '../axios/api';
import { servicios } from '../helpers/index';

export const UBIGUEO_PROCESS = '[Ubigueo] Ubigueo procesando';
export const UBIGUEO_LIST_DEPARTAMENTOS =
  '[Ubigueo] Ubigueo lista departamentos';
export const UBIGUEO_LIST_PROVINCIAS = '[Ubigueo] Ubigueo lista provincias';
export const UBIGUEO_LIST_DISTRITOS = '[Ubigueo] Ubigueo lista distritos';

export const getDepartamentosAction = () => (dispatch) => {
  dispatch({
    type: UBIGUEO_PROCESS,
  });

  api
    .get(`${servicios.UBIGUEO}`)
    .then((response) => {
      dispatch({
        type: UBIGUEO_LIST_DEPARTAMENTOS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: UBIGUEO_LIST_DEPARTAMENTOS,
        payload: [],
      });
    });
};

export const getProvinciasAction = (cd) => (dispatch) => {
  dispatch({
    type: UBIGUEO_PROCESS,
  });

  const params = {
    cd,
  };

  api
    .get(`${servicios.UBIGUEO}`, { params })
    .then((response) => {
      dispatch({
        type: UBIGUEO_LIST_PROVINCIAS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: UBIGUEO_LIST_PROVINCIAS,
        payload: [],
      });
    });
};

export const getDistritosAction = (cd, cp) => (dispatch) => {
  dispatch({
    type: UBIGUEO_PROCESS,
  });

  const params = {
    cd,
    cp,
  };

  api
    .get(`${servicios.UBIGUEO}`, { params })
    .then((response) => {
      dispatch({
        type: UBIGUEO_LIST_DISTRITOS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: UBIGUEO_LIST_DISTRITOS,
        payload: [],
      });
    });
};
