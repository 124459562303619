import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCabecerasAction } from '../../redux/actions/cabecera.action';

import CabeceraList from './CabeceraList';
import CabeceraItem from './CabeceraItem';
import CabeceraFilter from './CabeceraFilter';

const Cabecera = ({ openModal, getCabecerasAction }) => {
  useEffect(() => {
    getCabecerasAction({});
  }, []);
  return (
    <div className="op-component">
      <CabeceraList />
      <CabeceraFilter />
      {openModal && <CabeceraItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.cabecera.openModal,
  };
};

export default connect(mapStateToProps, { getCabecerasAction })(Cabecera);
