import React, { useState } from 'react';

import InfoIcon from '@material-ui/icons/Info';

const Tooltip = (props) => {
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <div className="op-info">
      <InfoIcon
        color="primary"
        onClick={() => setOpenInfo(!openInfo)}
        style={{ cursor: 'help' }}
      />
      <span className={`${openInfo ? 'on' : ''}`}>{props.children}</span>
    </div>
  );
};

export default Tooltip;
