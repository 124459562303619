export const sucursalModel = {
  su: null,
  ns: null,
  sp: null,
  di: null,
  ub: null,
  cd: null,
  cp: null,
  ci: null,
  du: null,
  dc: null,
};
