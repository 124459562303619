import Swal from 'sweetalert2';

export const confirmacion = (title, text) => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title,
      text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#1ab394',
      confirmButtonText: 'Confirmar',
      cancelButtonColor: 'red',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        resolve();
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: 'warning',
          title: 'Operación cancelada',
        });
        reject();
      }
    });
  });
};

export const mensaje = (title, icon = 'success', timer = 3000) => {
  Swal.fire({
    icon,
    title,
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
  });
};

export const mensajeMini = (title, icon = 'warning', timer = 3000) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
  });

  Toast.fire({
    icon,
    title,
  });
};
