import React, { useState } from 'react';
import Proptypes from 'prop-types';

import '../css/commons/tabla.css';
import { caracteresUnicode } from './Utilitarios';

const Tabla = ({
  columns,
  data,
  searcheable,
  pagination,
  rows,
  total,
  indice,
  processing,
  onPagination,
  onSearching,
  onChangeSearching,
  onDoubleClick,
  hoverSelectedRow,
  emptyRowsMessage,
  ...rest
}) => {
  const [page, setPage] = useState(1);

  const headers = () =>
    columns.map((item, index) => (
      <th
        key={item.key}
        width={item.width}
        className={`${index > 1 ? 'sm-hide' : ''}`}
      >
        {item.label ? item.label : ''}
      </th>
    ));

  const cell = (obj, fila, index) => (
    <td
      key={obj.key}
      align={obj.align}
      className={`${index > 2 ? 'sm-hide' : ''}`}
      data-label={index > 1 && obj.label ? `${obj.label} : ` : ''}
    >
      {index === 0 ? (
        <>
          <label
            onClick={(e) => handleHide(e)}
            className="op-table-ocultar"
          ></label>
          {obj.content ? obj.content(fila) : fila[obj.key]}
        </>
      ) : obj.content ? (
        obj.content(fila)
      ) : (
        fila[obj.key]
      )}
    </td>
  );

  const row = (fila, key) => (
    <tr
      key={key}
      onClick={(e) => handleClickRow(e)}
      onDoubleClick={() => {
        onDoubleClick ? onDoubleClick(fila) : {};
      }}
    >
      {columns.map((obj, index) => cell(obj, fila, index))}
    </tr>
  );

  const elements = [];
  if (!(total && total > 0)) {
    pagination = false;
  } else {
    for (let i = 0; i < total / rows; i++) {
      elements.push(i);
    }
  }

  let datos = null;
  if (processing) {
    datos = datos = (
      <tr className="op-procesando">
        <td colSpan={columns.length}>
          Procesando...
          <i className="op-loading-mini"></i>
        </td>
      </tr>
    );
  } else if (data.length === 0) {
    datos = (
      <tr className="op-vacio">
        <td colSpan={columns.length}>{emptyRowsMessage}</td>
      </tr>
    );
  } else {
    datos = data.map((i) => row(i, i[Object.keys(i)[indice]]));
  }

  function handleClickRow(e) {
    e.preventDefault();
    let fila = e.target;
    fila
      .closest('tbody')
      .querySelectorAll('tr')
      .forEach((element) => {
        element.classList.remove('op-seleccionado');
      });

    fila.parentElement.classList.add('op-seleccionado');
  }

  const handleClickPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let page = e.target;

    setPage(parseInt(page.dataset.page) + 1);
    onPagination({ page: parseInt(page.dataset.page), rows });
  };

  const handleHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.closest('tr').classList.toggle('off');
  };

  const handlePaginado = () => {
    let p = Math.trunc(page / rows) + 1;
    return p * rows > total ? total : p * rows;
  };

  const handleMovePage = (valor) => {
    let p = Math.trunc(page / rows) + valor;
    let newPage = p * rows + 1;
    setPage(newPage);
    onPagination({ page: newPage - 1, rows });
  };

  const handlePressSearch = (e) => {
    let re = new RegExp(`[a-zA-Z0-9-\_${caracteresUnicode()}]`);
    if (e.key === 'Enter') {
      setPage(1);
      onSearching(e.target.value);
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="op-table" {...rest}>
      {searcheable && (
        <div className="op-search">
          <input
            type="text"
            placeholder="Buscar..."
            data-search="texto"
            onKeyPress={(e) => handlePressSearch(e)}
            onChange={(e) => onChangeSearching(e.target.value)}
            onPaste={(e) => e.preventDefault()}
          />
          <i
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPage(1);
              onSearching(
                e.target.closest('.op-search').querySelector('input').value
              );
            }}
            className="op-icon-search"
          ></i>
        </div>
      )}
      <table>
        <thead>
          <tr>{headers()}</tr>
        </thead>
        <tbody className={`${hoverSelectedRow ? 'op-hover' : ''}`}>
          {datos}
        </tbody>
      </table>
      {pagination && (
        <div className="op-pagination">
          <span>{`Del ${page} al ${handlePaginado()} de ${total} registros`}</span>
          <ul>
            <li
              className={`op-prev${page === 1 ? ' disabled' : ''}`}
              onClick={() => handleMovePage(-1)}
            ></li>
            {elements.map((item) => (
              <li
                key={item}
                onClick={handleClickPage}
                data-page={rows * item}
                data-rows={rows}
                className={`op-pagina${
                  rows * item === page - 1 ? ' op-current' : ''
                }`}
              >
                {item + 1}
              </li>
            ))}
            <li
              className={`op-next${
                Math.trunc(page / rows) + 1 === elements.length
                  ? ' disabled'
                  : ''
              }`}
              onClick={() => handleMovePage(+1)}
            ></li>
          </ul>
        </div>
      )}
    </div>
  );
};

Tabla.propTypes = {
  columns: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.string.isRequired,
      label: Proptypes.string,
      align: Proptypes.oneOf(['center', 'left', 'right']),
      width: Proptypes.string,
      content: Proptypes.func,
    })
  ),
  data: Proptypes.array.isRequired,
  onPagination: Proptypes.func,
  onSearching: Proptypes.func,
  onChangeSearching: Proptypes.func,
  onDoubleClick: Proptypes.func,
  processing: Proptypes.bool,
  pagination: Proptypes.bool,
  searcheable: Proptypes.bool,
  hoverSelectedRow: Proptypes.bool,
  total: Proptypes.number,
  rows: Proptypes.number,
  indice: Proptypes.number,
  emptyRowsMessage: Proptypes.string,
};

Tabla.defaultProps = {
  data: [],
  searcheable: true,
  pagination: true,
  rows: 10,
  processing: false,
  indice: 0,
  hoverSelectedRow: true,
  emptyRowsMessage: 'No se encontraron registros',
};

export default Tabla;
