import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import appReducer from './reducers/app.reducer';
import usuarioReducer from './reducers/usuario.reducer';
import almacenReducer from './reducers/almacen.reducer';
import cabeceraReducer from './reducers/cabecera.reducer';
import detalleReducer from './reducers/detalle.reducer';
import sucursalReducer from './reducers/sucursal.reducer';
import ubigueoReducer from './reducers/ubigueo.reducer';
import clienteReducer from './reducers/cliente.reducer';
import actividadReducer from './reducers/actividad.reducer';
import archivoReducer from './reducers/archivo.reducer';
import { isLogginIn } from './actions/app.action';

let rootReducer = combineReducers({
  app: appReducer,
  usuario: usuarioReducer,
  almacen: almacenReducer,
  cabecera: cabeceraReducer,
  detalle: detalleReducer,
  sucursal: sucursalReducer,
  ubigueo: ubigueoReducer,
  cliente: clienteReducer,
  actividad: actividadReducer,
  archivo: archivoReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default function generateStore() {
  let store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  isLogginIn()(store.dispatch, store.getState);
  return store;
}
