import * as fromArchivo from '../actions/archivo.action';
import { archivoModel } from '../models/archivo.model';

let initialState = {
  archivos: [],
  su: null,
  suf: null,
  ur: null,
  ea: null,
  fi: null,
  ff: null,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
  openModalView: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromArchivo.ARCHIVO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: archivoModel,
      };
    case fromArchivo.ARCHIVO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        archivos: action.payload.data,
        total: action.payload.count,
      };
    case fromArchivo.ARCHIVO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        archivos: [],
        total: 0,
      };
    case fromArchivo.ARCHIVO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: archivoModel,
        openModal: true,
      };
    case fromArchivo.ARCHIVO_VIEW:
      return {
        ...state,
        selected: action.payload,
        errors: archivoModel,
        openModalView: true,
      };
    case fromArchivo.ARCHIVO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        openModalView: false,
        errors: archivoModel,
      };
    case fromArchivo.ARCHIVO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromArchivo.ARCHIVO_FILTER:
      return {
        ...state,
        suf: action.payload.suf,
        su: action.payload.su,
        ur: action.payload.ur,
        ea: action.payload.ea,
        fi: action.payload.fi,
        ff: action.payload.ff,
      };
    case fromArchivo.ARCHIVO_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    case fromArchivo.ARCHIVO_CLOSE:
      return {
        ...state,
        errors: archivoModel,
        openModal: false,
        openModalView: false,
      };
    default:
      return state;
  }
}
