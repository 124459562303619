export const clienteModel = {
  cl: null,
  td: null,
  dt: null,
  nd: null,
  dn: null,
  di: null,
  tf: null,
  ce: null,
  st: false,
};
