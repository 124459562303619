import React, { useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postCabeceraAction,
} from '../../redux/actions/cabecera.action';

import SaveIcon from '@material-ui/icons/Save';

const CabeceraItem = ({
  selected,
  processing,
  errors,
  postCabeceraAction,
  closeModal,
}) => {
  const [cabecera, setCabecera] = useState(selected);

  const handleChange = (item) => {
    const { name, value } = item;
    setCabecera((prev) => ({
      ...prev,
      [name]: name === 'co' ? value.toUpperCase() : value,
    }));
  };
  return (
    <Modal
      title={`${cabecera.ca ? 'Actualizar' : 'Nueva'} cabecera`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${cabecera.ca ? 'Actualización' : 'Un nueva'} cabecera`
            )
              .then(() => postCabeceraAction(cabecera))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            closeModal();
          })
          .catch(() => {})
      }
    >
      <Texto
        name="co"
        value={cabecera.co}
        label="C&oacute;digo"
        tabIndex={10}
        error={errors.co}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="de"
        value={cabecera.de}
        label="Nombre"
        tabIndex={11}
        error={errors.de}
        onChange={handleChange}
      />
      {cabecera.ca && (
        <div className="op-form-group op-col-1 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox name="st" checked={cabecera.st} onChange={handleChange} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.cabecera.selected,
    processing: state.cabecera.processing,
    errors: state.cabecera.errors,
  };
};

export default connect(mapStateToProps, { postCabeceraAction, closeModal })(
  CabeceraItem
);
