export const archivoModel = {
  ar: null,
  su: null,
  ns: null,
  ur: null,
  nu: null,
  fr: null,
  ta: null,
  dt: null,
  vm: null,
  ua: null,
  na: null,
  fa: null,
  fd: null,
  mt: null,
  dtl: [],
};
