import * as fromDetalle from '../actions/detalle.action';
import { detalleModel } from '../models/detalle.model';

let initialState = {
  detalles: [],
  tipoAlmacenes: [],
  tipoUnidadMedida: [],
  estadoArchivos: [],
  ca: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromDetalle.DETALLE_PROCESS:
      return {
        ...state,
        processing: true,
        errors: detalleModel,
      };
    case fromDetalle.DETALLE_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        detalles: action.payload.data,
        total: action.payload.count,
      };
    case fromDetalle.DETALLE_LIST_FAIL:
      return {
        ...state,
        processing: false,
        detalles: [],
        total: 0,
      };
    case fromDetalle.DETALLE_TIPOALMACEN:
      return {
        ...state,
        tipoAlmacenes: action.payload,
      };
    case fromDetalle.DETALLE_UNIDADMEDIDA:
      return {
        ...state,
        tipoUnidadMedida: action.payload,
      };
    case fromDetalle.DETALLE_ESTADOARCHIVOS:
      return {
        ...state,
        estadoArchivos: action.payload,
      };
    case fromDetalle.DETALLE_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
      };
    case fromDetalle.DETALLE_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: detalleModel,
      };
    case fromDetalle.DETALLE_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromDetalle.DETALLE_FILTER:
      return {
        ...state,
        ca: action.payload.ca,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromDetalle.DETALLE_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: detalleModel,
      };
    default:
      return state;
  }
}
