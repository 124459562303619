import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';

import {
  getActividadesAction,
  getSeleccionarActividadAction,
  actualizarFiltros,
} from '../../redux/actions/actividad.action';

const ActividadFilter = ({ sucursales, sucursal, perfil }) => {
  const dispatch = useDispatch();
  return (
    <Filtros
      onFilter={() => dispatch(getActividadesAction())}
      onNew={() => dispatch(getSeleccionarActividadAction())}
      showFilterButton={perfil === 'NOBASICO'}
      showNewButton={perfil === 'NOBASICO'}
    >
      {perfil === 'NOBASICO' && (
        <Combo
          name="suf"
          label="Sucursal"
          value={sucursal}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
          onChange={({ value }) => {
            dispatch(actualizarFiltros({ suf: value }));
          }}
        />
      )}
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    sucursal: state.actividad.suf,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(ActividadFilter);
