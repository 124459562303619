import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { cabeceraModel } from '../models/cabecera.model';

export const CABECERA_PROCESS = '[Cabecera] Cabecera procesando';
export const CABECERA_FAIL = '[Cabecera] Cabecera proceso fail';
export const CABECERA_SAVE_SUCCESS = '[Cabecera] Cabecera proceso exito';
export const CABECERA_LIST_SUCCESS = '[Cabecera] Cabecera lista exito';
export const CABECERA_LIST_FAIL = '[Cabecera] Cabecera lista error';
export const CABECERA_SELECTED = '[Cabecera] Cabecera seleccionado';
export const CABECERA_CLOSE = '[Cabecera] Cabecera cerrar modal';
export const CABECERA_FILTER = '[Cabecera] Cabecera filtros';

export const getCabecerasAction = ({ page, pageSize }) => (
  dispatch,
  getState
) => {
  let { search } = getState().cabecera;

  dispatch({
    type: CABECERA_PROCESS,
  });

  const params = {
    search,
    page,
    pageSize,
  };

  api
    .get(servicios.CABECERA, { params })
    .then((response) => {
      dispatch({
        type: CABECERA_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CABECERA_LIST_FAIL,
      });
    });
};

export const getSeleccionarCabeceraAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: CABECERA_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: CABECERA_SELECTED,
      payload: cabeceraModel,
    });
  }
};

export const postCabeceraAction = (item) => (dispatch) => {
  dispatch({
    type: CABECERA_PROCESS,
  });

  api
    .post(servicios.CABECERA, item)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCabecerasAction({}));
      dispatch({ type: CABECERA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CABECERA_FAIL,
        payload: error.data.errors ? error.data.errors : cabeceraModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { search } = getState().cabecera;

  let item = {
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: CABECERA_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CABECERA_CLOSE,
  });
};
