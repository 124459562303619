import * as fromCliente from '../actions/cliente.action';
import { clienteModel } from '../models/cliente.model';

let initialState = {
  clientes: [],
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCliente.CLIENTE_PROCESS:
      return {
        ...state,
        processing: true,
        errors: clienteModel,
      };
    case fromCliente.CLIENTE_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        clientes: action.payload.data,
        total: action.payload.count,
      };
    case fromCliente.CLIENTE_LIST_FAIL:
      return {
        ...state,
        processing: false,
        clientes: [],
        total: 0,
      };
    case fromCliente.CLIENTE_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
        errors: clienteModel,
      };
    case fromCliente.CLIENTE_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        selected: null,
        errors: clienteModel,
      };
    case fromCliente.CLIENTE_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCliente.CLIENTE_FILTER:
      return {
        ...state,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromCliente.CLIENTE_CLOSE:
      return {
        ...state,
        errors: clienteModel,
        openModal: false,
      };
    default:
      return state;
  }
}
