import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import Modal from '../../commons/Modal';

import {
  getActividadesAction,
  actualizarFiltros,
} from '../../redux/actions/actividad.action';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import { formatearFecha, formatoFecha } from '../../commons/Utilitarios';

const ActividadList = ({
  actividades,
  processing,
  total,
  rows,
  searchable,
  onSend,
  onClose,
}) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const dispatch = useDispatch();

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: '#3498db', contrastText: '#fff' },
    },
  });

  const columns = [
    {
      key: 'ta',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <div title="Enviar">
              <CheckIcon
                onClick={() => {
                  onSend(item);
                  onClose();
                }}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          ) : (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => {}}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'fa',
      label: 'Fecha asignada',
      content: (item) => formatoFecha(item.fa),
    },
    {
      key: 'nu',
      label: 'Responsable',
    },
    {
      key: 'dt',
      label: 'Descripción',
    },
    {
      key: 'ds',
      label: 'Estado tarea',
    },
  ];

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de tareas"
          closePressEscape={false}
          onClose={() => onClose()}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                label="A&ntilde;o y mes"
                helperText="Mes de tareas asignadas"
                value={selectedDate}
                autoOk={true}
                format="YYYY-MM"
                onChange={handleDateChange}
                onMonthChange={(item) => {
                  dispatch(
                    actualizarFiltros({
                      fi: formatearFecha(
                        new Date(item._d.getFullYear(), item._d.getMonth(), 1),
                        'YYYY-MM-DD'
                      ),
                    })
                  );
                  dispatch(
                    actualizarFiltros({
                      ff: formatearFecha(
                        new Date(
                          item._d.getFullYear(),
                          item._d.getMonth() + 1,
                          0
                        ),
                        'YYYY-MM-DD'
                      ),
                    })
                  );
                  dispatch(getActividadesAction());
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          <Tabla
            columns={columns}
            data={actividades}
            processing={processing}
            total={total}
            rows={rows}
            pagination={false}
            searcheable={false}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque titulo="Resultado de b&uacute;squeda">
          <Tabla
            columns={columns}
            data={actividades}
            processing={processing}
            total={total}
            rows={rows}
            pagination={false}
            searcheable={false}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    actividades: state.actividad.actividades,
    processing: state.actividad.processing,
    total: state.actividad.total,
    rows: state.actividad.rows,
  };
};

export default connect(mapStateToProps)(ActividadList);
