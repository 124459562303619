import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import lottie from 'lottie-web';

import '../css/commons/loading.css';

const Loading = ({ tipo }) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: handleType(),
    });
  }, []);

  const handleType = () => {
    switch (tipo) {
      case 'files':
        return require('../assets/json/files-transfer.json');
      case 'tasks':
        return require('../assets/json/loading-task.json');
      default:
        return require('../assets/json/loading-circles.json');
    }
  };

  return (
    <div className="op-content-loader">
      <div className="container" ref={container}></div>
    </div>
  );
};

Loading.propTypes = {
  tipo: Proptypes.string,
};

Loading.defaultProps = {
  tipo: 'default',
};

export default Loading;
