import { api } from '../axios/api';
import { constantes, servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { usuarioModel } from '../models/usuario.model';
import { encriptado } from '../../commons/Utilitarios';

import { LOGOUT } from './app.action';

export const USUARIO_PROCESS = '[Usuario] Usuario procesando';
export const USUARIO_PROCESS_SUCCESS = '[Usuario] Usuario confirmar empresa';
export const USUARIO_PROCESS_FAIL = '[Usuario] Usuario grabar error';
export const USUARIO_USERS_SUCCESS = '[Usuario] Usuario lista usuarios exito';
export const USUARIO_USERS_FAIL = '[Usuario] Usuario lista usuarios error';
export const USUARIO_ROLES = '[Usuario] Usuario listado roles';

export const getUsuariosAction = (is) => (dispatch) => {
  dispatch({
    type: USUARIO_PROCESS,
  });

  api
    .get(`${servicios.USUARIO}/${is}/sucursal`)
    .then((response) => {
      dispatch({
        type: USUARIO_USERS_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: USUARIO_USERS_FAIL,
      });
    });
};

export const getRolesAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: USUARIO_PROCESS,
  });

  api
    .get(
      `${servicios.ROL}/${currentUser.ie}/empresa?ap=${constantes.APLICACION}`
    )
    .then((response) => {
      dispatch({
        type: USUARIO_ROLES,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: USUARIO_ROLES,
        payload: [],
      });
    });
};

export const patchCambioUsuarioAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: USUARIO_PROCESS,
  });

  let user = {
    us: currentUser.us,
    cu: currentUser.cu,
    ia: constantes.APLICACION,
    ac: encriptado(item.ac),
    nu: encriptado(item.nu),
    nc: encriptado(item.nc),
  };

  api
    .patch(`${servicios.USUARIO}/cambio`, user)
    .then(() => {
      mensaje('Operación exitosa');
      localStorage.removeItem('currentUser');
      dispatch({
        type: LOGOUT,
      });
    })
    .catch((error) => {
      dispatch({
        type: USUARIO_PROCESS_FAIL,
        payload: error.data.errors ? error.data.errors : usuarioModel,
      });
    });
};

export const patchConfirmarEmpresaAction = (em, au) => (dispatch) => {
  dispatch({
    type: USUARIO_PROCESS,
  });

  api
    .patch(`${servicios.EMPRESA}/confirmar/${em}?au=${au}`)
    .then(() => {
      dispatch({
        type: USUARIO_PROCESS_SUCCESS,
        payload:
          'Felicitaciones su confirmación fue exitosa, por favor verificar su correo',
      });
    })
    .catch((error) => {
      dispatch({
        type: USUARIO_PROCESS_FAIL,
        payload: error.data.detail,
      });
    });
};
