import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import SucursalList from './SucursalList';
import SucursalItem from './SucursalItem';
import SucursalFilter from './SucursalFilter';
import SucursalNew from './SucursalNew';

const Sucursal = ({ openModal, openModalEdit, getSucursalesAction }) => {
  useEffect(() => {
    getSucursalesAction({});
  }, []);

  return (
    <div className="op-component">
      <SucursalList />
      <SucursalFilter />
      {openModal && <SucursalNew />}
      {openModalEdit && <SucursalItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.sucursal.openModal,
    openModalEdit: state.sucursal.openModalEdit,
  };
};

export default connect(mapStateToProps, {
  getSucursalesAction,
})(Sucursal);
