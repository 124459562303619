import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getCabecerasAction,
  getSeleccionarCabeceraAction,
  actualizarFiltros,
} from '../../redux/actions/cabecera.action';

import EditIcon from '@material-ui/icons/Edit';

const CabeceraList = ({
  cabeceras,
  processing,
  total,
  getCabecerasAction,
  getSeleccionarCabeceraAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'ca',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => getSeleccionarCabeceraAction(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'co',
      label: 'Código',
    },
    {
      key: 'de',
      label: 'Descripción',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];
  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={cabeceras}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getCabecerasAction({ page, pageSize: rows })
        }
        onSearching={() => getCabecerasAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    cabeceras: state.cabecera.cabeceras,
    processing: state.cabecera.processing,
    total: state.cabecera.total,
  };
};

export default connect(mapStateToProps, {
  getCabecerasAction,
  getSeleccionarCabeceraAction,
  actualizarFiltros,
})(CabeceraList);
