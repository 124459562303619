import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import { formatoFecha } from '../../commons/Utilitarios';
import {
  paginado,
  getArchivosAction,
  getSeleccionarArchivoAction,
} from '../../redux/actions/archivo.action';

import EditIcon from '@material-ui/icons/Edit';

const ArchivoList = ({ archivos, processing, total, rows }) => {
  const dispatch = useDispatch();

  const handleType = (estado) => {
    switch (estado) {
      case 'APRARC':
        return 'op-success';
      case 'DESARC':
        return 'op-error';
      default:
        return '';
    }
  };

  const columns = [
    {
      key: 'ar',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => dispatch(getSeleccionarArchivoAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'fr',
      label: 'Fecha registro',
      content: (item) => (
        <span className={handleType(item.vm)}>{formatoFecha(item.fr)}</span>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
      content: (item) => <span className={handleType(item.vm)}>{item.ns}</span>,
    },
    {
      key: 'nu',
      label: 'Responsable',
      content: (item) => <span className={handleType(item.vm)}>{item.nu}</span>,
    },
    {
      key: 'dt',
      label: 'Tarea',
      content: (item) => <span className={handleType(item.vm)}>{item.dt}</span>,
    },
    {
      key: 'de',
      label: 'Estado archivo',
      content: (item) => <span className={handleType(item.vm)}>{item.de}</span>,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={archivos}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getArchivosAction());
        }}
        searcheable={false}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    archivos: state.archivo.archivos,
    processing: state.archivo.processing,
    total: state.archivo.total,
    rows: state.archivo.rows,
  };
};

export default connect(mapStateToProps)(ArchivoList);
