import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import File from '../app/File';
import FileUpload from '../../commons/FileUpload';

import { getDatos, patchUpdateLogo } from '../../redux/actions/app.action';

import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';

import { confirmacion } from '../../commons/Mensajes';

const Empresa = () => {
  const [imagen, setImage] = useState(null);
  const dispatch = useDispatch();

  const { datos, processing, currentUser } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getDatos());
  }, []);

  return (
    <div className="op-component">
      <Bloque titulo="Datos de empresa">
        <div className="op-content-informativo">
          <div className="op-informativo" style={{ marginBottom: '10px' }}>
            <label className="titulo">Datos de la empresa</label>
            {datos && (
              <>
                <div className="op-datos">
                  <span>Empresa</span>
                  <b>{datos.np}</b>
                </div>
                <div className="op-datos">
                  <span>N&uacute;mero documento</span>
                  <b>{datos.nd}</b>
                </div>
                {datos.ce.split(',').map((x, i) => (
                  <div key={i} className="op-datos">
                    <span>{`Correo ${
                      i === 0 ? 'principal' : 'secundario'
                    }`}</span>
                    <b>{x}</b>
                  </div>
                ))}
                {datos.le && (
                  <File
                    filename={datos.le}
                    dir={[currentUser.ie]}
                    onClose={() => {}}
                    showInline
                  />
                )}
              </>
            )}
          </div>
          <FileUpload
            label={'Logo'}
            extensions={['jpg', 'jpeg', 'png']}
            onSelectedFile={(files) => {
              setImage(files[0].file);
            }}
          />
          <Boton
            className="op-dinamic"
            disabled={processing}
            icon={() => <RefreshIcon />}
            style={{ '--button-color': '#3498db' }}
            onClick={() => dispatch(getDatos())}
          >
            Refrescar
          </Boton>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            onClick={() =>
              confirmacion(
                `¿Desea guardar la imagen}?`,
                `Este sera el logo que aparecera en tus reportes`
              )
                .then(() => dispatch(patchUpdateLogo(imagen)))
                .catch(() => {})
            }
          >
            Guardar logo
          </Boton>
        </div>
      </Bloque>
    </div>
  );
};

export default Empresa;
