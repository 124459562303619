import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { almacenModel } from '../models/almacen.model';

export const ALMACEN_PROCESS = '[Almacen] almacen procesando';
export const ALMACEN_SAVE_SUCCESS = '[Almacen] almacen guardar exito';
export const ALMACEN_LIST_SUCCESS = '[Almacen] almacen lista exito';
export const ALMACEN_LIST_FAIL = '[Almacen] almacen lista error';
export const ALMACEN_SELECTED = '[Almacen] almacen seleccionado';
export const ALMACEN_CLOSE = '[Almacen] almacen cerrar modal';
export const ALMACEN_FILTER = '[Almacen] almacen filtros';
export const ALMACEN_FAIL = '[Almacen] almacen proceso error';

export const getAlmacenesAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { iu, su, ta, st, search } = getState().almacen;
    let { currentUser } = getState().app;

    dispatch({
      type: ALMACEN_PROCESS,
    });

    const params = {
      ie: currentUser.ie,
      iu,
      su,
      ta,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.ALMACEN, { params })
      .then((response) => {
        dispatch({
          type: ALMACEN_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: ALMACEN_LIST_FAIL,
        });
      });
  };

export const getSeleccionarAlmacenAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: ALMACEN_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: ALMACEN_SELECTED,
      payload: almacenModel,
    });
  }
};

export const postAlmacenAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ALMACEN_PROCESS,
  });

  let almacen = {
    al: item.al,
    ie: currentUser.ie,
    su: item.su,
    na: item.na,
    ta: item.ta,
    st: item.st,
  };

  api
    .post(servicios.ALMACEN, almacen)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getAlmacenesAction({}));
      dispatch({ type: ALMACEN_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: ALMACEN_FAIL,
        payload: error.data.errors ? error.data.errors : almacenModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { iu, su, ta, st, search } = getState().almacen;

  let item = {
    iu: filtros.iu !== undefined ? (filtros.iu === '' ? null : filtros.iu) : iu,
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ta: filtros.ta !== undefined ? (filtros.ta === '' ? null : filtros.ta) : ta,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: ALMACEN_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ALMACEN_CLOSE,
  });
};
