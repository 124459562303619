import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCabecerasAction } from '../../redux/actions/cabecera.action';
import { getDetallesAction } from '../../redux/actions/detalle.action';

import DetalleList from './DetalleList';
import DetalleFilter from './DetalleFilter';
import DetalleItem from './DetalleItem';

const Detalle = ({ openModal, getDetallesAction, getCabecerasAction }) => {
  useEffect(() => {
    getDetallesAction({});
    getCabecerasAction({ page: 0, pageSize: 30 });
  }, []);

  return (
    <div className="op-component">
      <DetalleList />
      <DetalleFilter />
      {openModal && <DetalleItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.detalle.openModal,
  };
};

export default connect(mapStateToProps, {
  getCabecerasAction,
  getDetallesAction,
})(Detalle);
