import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import generateStore from './redux/store';

import App from './App';

let store = generateStore();
let WithStore = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(<WithStore />, document.getElementById('root'));
