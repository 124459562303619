import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import Bloque from '../../commons/Bloque';
import Calendario from '../../commons/Calendario';

import {
  getActividadesAction,
  actualizarFiltros,
} from '../../redux/actions/actividad.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import ActividadFilter from './ActividadFilter';
import ActividadRegister from './ActividadRegister';

import '../../css/pages/actividad.css';
import { formatearFecha } from '../../commons/Utilitarios';

const Actividad = ({ actividades, openModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActividadesAction());
    dispatch(getSucursalesAction({}));
  }, []);

  return (
    <div className="op-component">
      <Bloque titulo="Activades asignadas">
        <Calendario
          tasks={actividades}
          showInline
          tabIndex={1}
          onSelected={() => {}}
          onSelectedMonth={({ month, year }) => {
            dispatch(
              actualizarFiltros({
                fi: formatearFecha(new Date(year, month, 1), 'YYYY-MM-DD'),
              })
            );
            dispatch(
              actualizarFiltros({
                ff: formatearFecha(new Date(year, month + 1, 0), 'YYYY-MM-DD'),
              })
            );
            dispatch(getActividadesAction());
          }}
        />
      </Bloque>
      <ActividadFilter />
      {openModal && <ActividadRegister />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    actividades: state.actividad.actividades,
    openModal: state.actividad.openModal,
  };
};

export default connect(mapStateToProps)(Actividad);
