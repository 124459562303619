import React from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getSeleccionarSucursalAction,
  getSucursalesAction,
} from '../../redux/actions/sucursal.action';

const SucursalFilter = ({
  getSeleccionarSucursalAction,
  getSucursalesAction,
}) => {
  return (
    <Filtros
      onFilter={() => getSucursalesAction({})}
      onNew={() => getSeleccionarSucursalAction()}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getSeleccionarSucursalAction,
  getSucursalesAction,
})(SucursalFilter);
