import React from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getClientesAction,
  getSeleccionarClienteAction,
} from '../../redux/actions/cliente.action';

const ClienteFilter = ({ getClientesAction, getSeleccionarClienteAction }) => {
  return (
    <Filtros
      onFilter={() => getClientesAction({})}
      onNew={() => getSeleccionarClienteAction()}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getClientesAction,
  getSeleccionarClienteAction,
})(ClienteFilter);
