import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../css/commons/bloque.css';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Bloque = (props) => {
  const [open, setOpen] = useState(true);
  const actionButtons = () => (
    <>
      {props.onActions ? (
        <div className="op-bloque-actions">
          {props.onActions()}
          <label className="icon" onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
          </label>
        </div>
      ) : (
        <label className="icon" onClick={() => setOpen(!open)}>
          <span></span>
          <span></span>
        </label>
      )}
    </>
  );

  return (
    <div className={`op-bloque${open ? '' : ' op-close'}`}>
      <div className="op-bloque-head">
        {props.onBackButton ? (
          <div className="op-bloque-actions">
            <ArrowBackIcon /> <span>{props.titulo}</span>
          </div>
        ) : (
          <span>{props.titulo}</span>
        )}
        {actionButtons()}
      </div>
      <div className="op-bloque-content">{props.children}</div>
    </div>
  );
};

Bloque.propTypes = {
  titulo: PropTypes.string,
  onActions: PropTypes.func,
  onBackButton: PropTypes.func,
};

Bloque.defaultProps = {
  titulo: 'Búsqueda',
};

export default Bloque;
