import React from 'react';
import { connect } from 'react-redux';

import Inicio from '../../pages/Inicio';
import Cambio from '../cambio/Cambio';
import Cabecera from '../cabecera/Cabecera';
import Detalle from '../detalle/Detalle';
import Almacen from '../almacen/Almacen';
import Sucursal from '../sucursal/Sucursal';
import Cliente from '../cliente/Cliente';
import Empresa from '../empresa/Empresa';
import Actividad from '../actividad/Actividad';
import Archivo from '../archivo/Archivo';

import '../../css/body.css';

const Body = ({ headers, closeMenu, selected }) => {
  let list = headers.map((head) => {
    switch (head.mm) {
      case 'NTA_CAMB':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Cambio />
          </div>
        );
      case 'CON_CABE':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Cabecera />
          </div>
        );
      case 'CON_DETA':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Detalle />
          </div>
        );
      case 'NTA_ALMA':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Almacen />
          </div>
        );
      case 'NTA_EMPR':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Empresa />
          </div>
        );
      case 'NTA_SUCU':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Sucursal />
          </div>
        );
      case 'NTA_CLI':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Cliente />
          </div>
        );
      case 'NTA_ACTI':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Actividad />
          </div>
        );
      case 'NTA_FILE':
        return (
          <div
            key={head.mm}
            className={`op-content-component${
              selected && selected.mm === head.mm ? '' : ' op-close'
            }`}
          >
            <Archivo />
          </div>
        );
      default:
        return null;
    }
  });

  return (
    <div className={`op-content-body${closeMenu === true ? ' active' : ''}`}>
      <div className="op-content-component">
        <Inicio />
      </div>
      {list}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headers: state.app.headers,
    closeMenu: state.app.closeMenu,
    selected: state.app.selected,
  };
};

export default connect(mapStateToProps)(Body);
