import { constantes } from './constantes';
export const servicios = {
  LOGIN: `${constantes.MS_AUTENTICACION}/v1/autentificacion/login`,
  CAPTCHA: `${constantes.MS_AUTENTICACION}/v1/captchas`,
  MENU: `${constantes.MS_AUDITORIA}/v1/menues`,
  USUARIO: `${constantes.MS_AUTENTICACION}/v1/usuarios`,
  EMPRESA: `${constantes.MS_AUTENTICACION}/v1/empresas`,
  ROL: `${constantes.MS_AUTENTICACION}/v1/roles`,
  ALMACEN: `${constantes.MS_LOGISTICA}/v1/almacenes`,
  CABECERA: `${constantes.MS_LOGISTICA}/v1/cabeceras`,
  DETALLE: `${constantes.MS_LOGISTICA}/v1/detalles`,
  FILES: `${constantes.MS_LOGISTICA}/v1/files`,
  SUCURSAL: `${constantes.MS_AUTENTICACION}/v1/sucursales`,
  UBIGUEO: `${constantes.MS_AUTENTICACION}/v1/ubigueos`,
  CLIENTE: `${constantes.MS_LOGISTICA}/v1/clientes`,
  TAREA: `${constantes.MS_LOGISTICA}/v1/tareas`,
  ARCHIVO: `${constantes.MS_LOGISTICA}/v1/archivos`,
};
