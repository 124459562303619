import * as fromCabecera from '../actions/cabecera.action';
import { cabeceraModel } from '../models/cabecera.model';

let initialState = {
  cabeceras: [],
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCabecera.CABECERA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: cabeceraModel,
      };
    case fromCabecera.CABECERA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        cabeceras: action.payload.data,
        total: action.payload.count,
      };
    case fromCabecera.CABECERA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        cabeceras: [],
        total: 0,
      };
    case fromCabecera.CABECERA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
      };
    case fromCabecera.CABECERA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: cabeceraModel,
      };
    case fromCabecera.CABECERA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCabecera.CABECERA_FILTER:
      return {
        ...state,
        search: action.payload.search,
      };
    case fromCabecera.CABECERA_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: cabeceraModel,
      };
    default:
      return state;
  }
}
