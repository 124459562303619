import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';

import { sucursalModel } from '../models/sucursal.model';

import { getProvinciasAction, getDistritosAction } from './ubigueo.action';

export const SUCURSAL_PROCESS = '[Sucursal] sucursal procesando';
export const SUCURSAL_SAVE_SUCCESS = '[Sucursal] sucursal guardar exitosa';
export const SUCURSAL_LIST_SUCCESS = '[Sucursal] sucursal lista exitosa';
export const SUCURSAL_LIST_FAIL = '[Sucursal] sucursal lista error';
export const SUCURSAL_FILTER = '[Sucursal] sucursal filtros';
export const SUCURSAL_SELECTED = '[Sucursal] sucursal seleccionado';
export const SUCURSAL_NEW = '[Sucursal] sucursal nueva';
export const SUCURSAL_CLOSE = '[Sucursal] sucursal close';
export const SUCURSAL_FAIL = '[Sucursal] sucursal proceso error';

export const getSucursalesAction = ({ page, pageSize }) => (
  dispatch,
  getState
) => {
  let { es, search } = getState().sucursal;
  let { currentUser } = getState().app;

  dispatch({
    type: SUCURSAL_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    es,
    search,
    page,
    pageSize,
  };
  api
    .get(`${servicios.SUCURSAL}`, { params })
    .then((response) => {
      dispatch({
        type: SUCURSAL_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: SUCURSAL_LIST_FAIL,
      });
    });
};

export const getSeleccionarSucursalAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: SUCURSAL_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: SUCURSAL_NEW,
      payload: sucursalModel,
    });
  }
};

export const postSucursalAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: SUCURSAL_PROCESS,
  });

  let sucursal = {
    em: currentUser.ie,
    ns: item.ns,
  };

  api
    .post(`${servicios.SUCURSAL}`, sucursal)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getSucursalesAction({}));
      dispatch({ type: SUCURSAL_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: SUCURSAL_FAIL,
        payload: error.data.errors ? error.data.errors : sucursalModel,
      });
    });
};

export const pathcSucursalAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: SUCURSAL_PROCESS,
  });

  let sucursal = {
    su: item.su,
    em: currentUser.ie,
    ns: item.ns,
    sp: item.sp,
    di: item.di,
    ub: item.ub,
    dc: item.dc,
  };

  api
    .put(`${servicios.SUCURSAL}/empresa`, sucursal)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getSucursalesAction({}));
      dispatch({ type: SUCURSAL_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: SUCURSAL_FAIL,
        payload: error.data.errors ? error.data.errors : sucursalModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { es, search } = getState().sucursal;

  let item = {
    es: filtros.es !== undefined ? (filtros.es === '' ? null : filtros.es) : es,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: SUCURSAL_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: SUCURSAL_CLOSE,
  });
};
