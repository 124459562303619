import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getClientesAction } from '../../redux/actions/cliente.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import ClienteList from './ClienteList';
import ClienteFilter from './ClienteFilter';
import ClienteItem from './ClienteItem';

const Cliente = ({ openModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientesAction({}));
    dispatch(getDetallesPorCabeceraAction({ ca: 'TIPODOCUMENTOIDENTIDAD' }));
  }, []);

  return (
    <div className="op-component">
      <ClienteList />
      <ClienteFilter />
      {openModal && <ClienteItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.cliente.openModal,
  };
};

export default connect(mapStateToProps)(Cliente);
