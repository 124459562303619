import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import FileUpload from '../../commons/FileUpload';
import { confirmacion } from '../../commons/Mensajes';

import ActividadList from '../actividad/ActividadList';

import {
  closeModal,
  postRegistarArchivoAction,
} from '../../redux/actions/archivo.action';
import { getActividadesAction } from '../../redux/actions/actividad.action';

import SaveIcon from '@material-ui/icons/Save';

const ArchivoRegister = () => {
  const usuario = useSelector((state) => state.app.currentUser.np);
  const { processing, selected, errors } = useSelector(
    (state) => state.archivo
  );
  const [archivo, setArchivo] = useState(selected);
  const [open, setOpen] = useState(false);
  const [documento, setDocumento] = useState(null);

  const dispatch = useDispatch();

  const handleChange = (item) => {
    const { name, value } = item;
    setArchivo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Registrar archivo`}
        processing={processing}
        loading="files"
        onActions={() => (
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion('¿Desea guardar?', `El archivo`)
                .then(() =>
                  dispatch(postRegistarArchivoAction(archivo, documento))
                )
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        <Texto
          name="user"
          value={usuario}
          label="Responsable"
          tabIndex={10}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="dt"
          value={archivo.dt}
          label="Tarea"
          tabIndex={13}
          size={2}
          error={errors.ta}
          onChange={() => {}}
          search
          onSearch={() => {
            setOpen(true);
            dispatch(getActividadesAction());
          }}
        />
        <FileUpload
          extensions={['pdf', 'xls', 'xlsx', 'doc', 'docx']}
          onSelectedFile={(files) => {
            setDocumento(files.length > 0 ? files[0].file : null);
          }}
        />
      </Modal>
      {open && (
        <ActividadList
          onSend={(item) => {
            handleChange({ name: 'ta', value: item.ta });
            handleChange({ name: 'dt', value: item.dt });
          }}
          onClose={() => setOpen(false)}
          searchable
        />
      )}
    </>
  );
};

export default ArchivoRegister;
