import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  pathcSucursalAction,
} from '../../redux/actions/sucursal.action';

import {
  getDepartamentosAction,
  getProvinciasAction,
  getDistritosAction,
} from '../../redux/actions/ubigueo.action';

import SaveIcon from '@material-ui/icons/Save';

const SucursalItem = ({
  selected,
  processing,
  processingUbigueo,
  errors,
  departamentos,
  provincias,
  distritos,
  closeModal,
  pathcSucursalAction,
  getDepartamentosAction,
  getProvinciasAction,
  getDistritosAction,
}) => {
  const [sucursal, setSucursal] = useState(selected);
  const [departamento, setDepartamento] = useState(
    selected.cd ? selected.cd : ''
  );
  const [provincia, setProvincia] = useState(selected.cp ? selected.cp : '');
  const [distrito, setDistrito] = useState(selected.ci ? selected.ci : '');

  useEffect(() => {
    getDepartamentosAction();
    if (selected.cd) {
      setTimeout(() => {
        getProvinciasAction(selected.cd);
      }, 10);
    }

    if (selected.cd && selected.cp) {
      setTimeout(() => {
        getDistritosAction(selected.cd, selected.cp);
      }, 10);
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setSucursal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`Actualizar sucursal`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Está seguro?', `Actualizar sucursal`)
              .then(() => pathcSucursalAction(sucursal))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            closeModal();
          })
          .catch(() => {})
      }
    >
      <Texto
        name="ns"
        value={sucursal.ns}
        label="Nombre"
        tabIndex={10}
        size={2}
        error={errors.ns}
        onChange={handleChange}
        autoFocus
      />
      <Combo
        name="sp"
        label="¿Es la principal?"
        value={sucursal.sp}
        tabIndex={11}
        size={2}
        data={[
          { value: 'S', label: 'Si' },
          { value: 'N', label: 'No' },
        ]}
        onChange={handleChange}
        error={errors.sp}
      />
      <Combo
        name="cd"
        label="Departamento"
        value={departamento}
        disabled={processingUbigueo}
        tabIndex={12}
        size={2}
        data={departamentos.map((i) => ({ value: i.cd, label: i.de }))}
        onChange={(item) => {
          setDepartamento(item.value);
          if (item.value) {
            getProvinciasAction(item.value);
          }
        }}
      />
      <Combo
        name="cp"
        label="Provincia"
        value={provincia}
        disabled={processingUbigueo}
        tabIndex={13}
        size={2}
        data={provincias.map((i) => ({ value: i.cp, label: i.de }))}
        onChange={(item) => {
          setProvincia(item.value);
          if (item.value) {
            getDistritosAction(departamento, item.value);
          }
        }}
      />
      <Combo
        name="ci"
        label="Distrito"
        value={distrito}
        disabled={processingUbigueo}
        tabIndex={14}
        size={2}
        data={distritos.map((i) => ({
          value: i.ci,
          label: i.de,
        }))}
        onChange={(item) => {
          setDistrito(item.value);
          let ubigueo = distritos.find((x) => x.ci === item.value);
          handleChange({ value: ubigueo ? ubigueo.ub : '', name: 'ub' });
        }}
        searcheable
      />
      <Texto
        name="dc"
        value={sucursal.dc}
        label="Direcci&oacute;n"
        tabIndex={15}
        size={2}
        error={errors.dc}
        onChange={handleChange}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.sucursal.selected,
    processing: state.sucursal.processing,
    errors: state.sucursal.errors,
    departamentos: state.ubigueo.departamentos,
    provincias: state.ubigueo.provincias,
    distritos: state.ubigueo.distritos,
    processingUbigueo: state.ubigueo.processing,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  pathcSucursalAction,
  getDepartamentosAction,
  getProvinciasAction,
  getDistritosAction,
})(SucursalItem);
