import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getDetallesAction,
  getSeleccionarDetalleAction,
  actualizarFiltros,
} from '../../redux/actions/detalle.action';

import EditIcon from '@material-ui/icons/Edit';

const DetalleList = ({
  detalles,
  processing,
  total,
  getDetallesAction,
  getSeleccionarDetalleAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'dt',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => getSeleccionarDetalleAction(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'de',
      label: 'Descripción',
    },
    {
      key: 'vm',
      label: 'Valor',
    },
    {
      key: 'dc',
      label: 'Cabecera',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={detalles}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getDetallesAction({ page, pageSize: rows })
        }
        onSearching={() => getDetallesAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    detalles: state.detalle.detalles,
    processing: state.detalle.processing,
    total: state.detalle.total,
  };
};

export default connect(mapStateToProps, {
  getDetallesAction,
  getSeleccionarDetalleAction,
  actualizarFiltros,
})(DetalleList);
