import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import FileUpload from '../../commons/FileUpload';
import Report from '../app/Report';
import { formatoFecha } from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postRegistarArchivoAction,
  patchAprobarArchivoAction,
  patchDesaprobarArchivoAction,
  patchDarBajaArchivoAction,
  patchEditarArchivoAction,
  patchRectificarArchivoAction,
} from '../../redux/actions/archivo.action';

import { servicios } from '../../redux/helpers/servicios';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import PdfFile from '../../assets/icons/pdf-file.svg';
import DocFile from '../../assets/icons/docx-file.svg';
import ExcelFile from '../../assets/icons/excel-file.svg';
import AddFile from '../../assets/icons/add-file.svg';
import AcceptFile from '../../assets/icons/file-accept.svg';
import CancelFile from '../../assets/icons/file-cancel.svg';

const ArchivoView = () => {
  const [openFile, setOpenFile] = useState(false);
  const [filename, setFilename] = useState(null);
  const [downloadname, setDownloadname] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [openNew, setOpenNew] = useState(false);

  const { selected, processing } = useSelector((state) => state.archivo);
  const [archivo, setArchivo] = useState(null);
  const empresa = useSelector((state) => state.app.currentUser.ie);
  const perfil = useSelector((state) => state.app.currentUser.tr);

  const dispatch = useDispatch();

  const handleGetIcon = (extension) => {
    let icono = PdfFile;
    switch (extension) {
      case 'pdf':
        icono = PdfFile;
        break;
      case 'doc':
        icono = DocFile;
        break;
      case 'docx':
        icono = DocFile;
        break;
      case 'xls':
        icono = ExcelFile;
        break;
      case 'xlsx':
        icono = ExcelFile;
        break;
      default:
        break;
    }
    return icono;
  };

  return (
    <>
      <Modal
        title={`Archivo`}
        processing={processing}
        loading={'files'}
        onActions={() => (
          <>
            {selected.vm !== 'APRARC' && perfil === 'NOBASICO' && (
              <>
                <Boton
                  className="op-dinamic"
                  disabled={processing}
                  icon={() => <Icon svg={CancelFile} color="red" />}
                  style={{ '--button-color': 'red' }}
                  tabIndex={20}
                  onClick={() => {
                    Swal.fire({
                      input: 'textarea',
                      inputLabel: 'Motivo de desaprobación',
                      inputPlaceholder: 'Ingrese el motivo de desaprobación...',
                      inputAttributes: {
                        'aria-label': 'Ingrese el motivo de desaprobación',
                        style: { resize: 'none' },
                      },
                      showCancelButton: true,
                      confirmButtonColor: '#1ab394',
                      confirmButtonText: 'Confirmar',
                      cancelButtonColor: 'red',
                      cancelButtonText: 'Cancelar',
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.value) {
                        confirmacion(
                          '¿Está seguro?',
                          `De desaprobar el documento`
                        )
                          .then(() => {
                            dispatch(
                              patchDesaprobarArchivoAction(
                                selected,
                                result.value
                              )
                            );
                          })
                          .catch(() => {});
                      }
                    });
                  }}
                >
                  Desaprobar
                </Boton>
                <Boton
                  className="op-dinamic"
                  disabled={processing}
                  icon={() => <Icon svg={AcceptFile} color="#1ab394" />}
                  style={{ '--button-color': '#1ab394' }}
                  tabIndex={20}
                  onClick={() =>
                    confirmacion(
                      '¿Está seguro?',
                      `De aprobar el documento, ya no podra realizar cambios`
                    )
                      .then(() => dispatch(patchAprobarArchivoAction(selected)))
                      .catch(() => {})
                  }
                >
                  Aprobar
                </Boton>
              </>
            )}
            {selected.vm === 'APRARC' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <Icon svg={CancelFile} color="red" />}
                style={{ '--button-color': 'red' }}
                tabIndex={20}
                onClick={() => {
                  Swal.fire({
                    input: 'textarea',
                    inputLabel: 'Motivo de rectificación',
                    inputPlaceholder: 'Ingrese el motivo de rectificación...',
                    inputAttributes: {
                      'aria-label': 'Ingrese el motivo de rectificación',
                      style: { resize: 'none' },
                    },
                    showCancelButton: true,
                    confirmButtonColor: '#1ab394',
                    confirmButtonText: 'Confirmar',
                    cancelButtonColor: 'red',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.value) {
                      confirmacion(
                        '¿Está seguro?',
                        `De rectificar el documento`
                      )
                        .then(() => {
                          dispatch(
                            patchRectificarArchivoAction(selected, result.value)
                          );
                        })
                        .catch(() => {});
                    }
                  });
                }}
              >
                Rectificar
              </Boton>
            )}
            {selected.vm !== 'APRARC' && (
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <Icon svg={AddFile} color="#3285a8" />}
                style={{ '--button-color': '#3285a8' }}
                tabIndex={20}
                onClick={() => setOpenNew(true)}
              >
                Agregar documento
              </Boton>
            )}
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        <Texto
          name="nu"
          value={selected.nu}
          label="Responsable"
          tabIndex={10}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="ns"
          value={selected.ns}
          label="Sucursal"
          tabIndex={11}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="fr"
          value={formatoFecha(selected.fr)}
          label="Fecha registro"
          tabIndex={12}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="de"
          value={selected.de}
          label="Estado"
          tabIndex={13}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="na"
          value={selected.na}
          label="Aprobador"
          tabIndex={14}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="fa"
          value={formatoFecha(selected.fa, 'Pendiente')}
          label="Fecha de aprobaci&oacute;n"
          tabIndex={15}
          size={2}
          disabled
          onChange={() => {}}
        />
        {selected.fd && (
          <>
            <Texto
              name="fd"
              value={formatoFecha(selected.fd, 'Pendiente')}
              label="Fecha de desaprobaci&oacute;n"
              tabIndex={16}
              size={2}
              disabled
              onChange={() => {}}
            />
            <Texto
              name="mt"
              value={selected.mt}
              label="Motivo"
              tabIndex={17}
              size={2}
              disabled
              onChange={() => {}}
            />
          </>
        )}
        <Texto
          name="dt"
          value={selected.dt}
          label="Tarea"
          tabIndex={18}
          disabled
          onChange={() => {}}
        />
        <div className="op-form-group">
          {selected.dtl.map((d) => (
            <div
              key={d.cr}
              style={{
                display: 'grid',
                gridTemplateColumns: '30px 20px auto',
                columnGap: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginRight: '5px',
                }}
              >
                {selected.vm !== 'APRARC' && (
                  <EditIcon
                    style={{
                      color: '#1ab394',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setArchivo(d);
                      setOpenNew(true);
                    }}
                  />
                )}
                {d.st && selected.vm !== 'APRARC' && (
                  <CloseIcon
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De anular el archivo ${d.na}.${d.ea}`
                      )
                        .then(() =>
                          dispatch(patchDarBajaArchivoAction(selected, d.cr))
                        )
                        .catch(() => {})
                    }
                  />
                )}
              </div>
              <Icon svg={handleGetIcon(d.ea)} transparent />
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setFilename(d.cr);
                  setExtension(d.ea);
                  setOpenFile(true);
                  setDownloadname(d.na);
                }}
              >
                <span
                  style={{
                    textDecoration: `${d.st ? 'none' : 'line-through'}`,
                  }}
                >{`${d.na}.${d.ea}`}</span>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      {openFile && (
        <Report
          url={`${servicios.ARCHIVO}/${selected.ar}/empresa/${empresa}/${filename}`}
          params={{}}
          downloadable={extension !== 'pdf'}
          extension={extension}
          filename={downloadname}
          onClose={() => setOpenFile(false)}
        />
      )}
      {openNew && (
        <Modal
          title={`${archivo ? 'Editar' : 'Agregar'} documento`}
          size="small"
          processing={processing}
          loading="files"
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion(
                  '¿Desea guardar?',
                  `Se agregara un nuevo documento al registro`
                )
                  .then(() => {
                    if (archivo) {
                      dispatch(
                        patchEditarArchivoAction(
                          selected,
                          archivo.cr,
                          documento
                        )
                      );
                    } else {
                      dispatch(postRegistarArchivoAction(selected, documento));
                    }
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          )}
          onClose={() => {
            setArchivo(null);
            setOpenNew(false);
          }}
        >
          {archivo && (
            <Texto
              name="nd"
              value={`${archivo.na}.${archivo.ea}`}
              label="Documento"
              tabIndex={36}
              disabled
              onChange={() => {}}
            />
          )}
          <FileUpload
            extensions={['pdf', 'xls', 'xlsx', 'doc', 'docx']}
            onSelectedFile={(files) => {
              setDocumento(files.length > 0 ? files[0].file : null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ArchivoView;
