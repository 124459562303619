import { formatearFecha } from '../../commons/Utilitarios';
import * as fromActividad from '../actions/actividad.action';
import { actividarModel } from '../models/actividad.model';

let initialState = {
  actividades: [],
  su: null,
  suf: null,
  ur: null,
  et: null,
  fi: formatearFecha(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    'YYYY-MM-DD'
  ),
  ff: formatearFecha(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    'YYYY-MM-DD'
  ),
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromActividad.ACTIVIDAD_PROCESS:
      return {
        ...state,
        processing: true,
        errors: actividarModel,
      };
    case fromActividad.ACTIVIDAD_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        actividades: action.payload,
      };
    case fromActividad.ACTIVIDAD_LIST_FAIL:
      return {
        ...state,
        processing: false,
        actividades: [],
      };
    case fromActividad.ACTIVIDAD_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: actividarModel,
        openModal: true,
      };
    case fromActividad.ACTIVIDAD_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: actividarModel,
      };
    case fromActividad.ACTIVIDAD_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromActividad.ACTIVIDAD_FILTER:
      return {
        ...state,
        suf: action.payload.suf,
        su: action.payload.su,
        ur: action.payload.ur,
        et: action.payload.et,
        fi: action.payload.fi,
        ff: action.payload.ff,
      };
    case fromActividad.ACTIVIDAD_CLOSE:
      return {
        ...state,
        errors: actividarModel,
        openModal: false,
      };
    default:
      return state;
  }
}
