import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from './pages/Login';
import Header from './components/app/Header';
import Menu from './components/app/Menu';
import Body from './components/app/Body';
import ConfirmarRegistro from './pages/ConfirmarRegistro';
import Olvido from './pages/Olvido';
import File from './components/app/File';
import RestablecerContrasenia from './pages/RestablecerContrasenia';

import './css/general.css';

const App = ({ loggedIn }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/confirmar-registro" component={ConfirmarRegistro} />
        <Route path="/olvido" component={Olvido} />
        <Route path="/archivo" component={File} />
        <Route path="/restablecer-usuario" component={RestablecerContrasenia} />
        <Route exact path="/">
          {loggedIn === true ? (
            <>
              <Header />
              <Menu />
              <Body />
            </>
          ) : (
            <Login />
          )}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProp = (state) => {
  return {
    loggedIn: state.app.loggedIn,
  };
};

export default connect(mapStateToProp)(App);
