import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';

import FileSearch from '../assets/icons/file-search.svg';
import PdfFile from '../assets/icons/pdf-file.svg';
import PngFile from '../assets/icons/png-file.svg';
import DocxFile from '../assets/icons/docx-file.svg';
import ExcelFile from '../assets/icons/excel-file.svg';
import JpgFile from '../assets/icons/jpg-file.svg';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import Boton from './Boton';
import { mensajeMini } from './Mensajes';

import '../css/commons/fileupload.css';

const FileUpload = ({ label, maxFiles, extensions, onSelectedFile }) => {
  const [numFiles, setNumFiles] = useState(1);
  const [correlativo, setCorrelativo] = useState(1);
  const [files, setFiles] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [rows, setRows] = useState([
    {
      id: 0,
      icon: FileSearch,
      label: 'Subir documento',
      size: 0,
    },
  ]);

  const handleChangeFile = (e, id) => {
    let current = e.target;
    if (current.files && current.files[0]) {
      let extension = current.files[0].name.split('.').pop().toLowerCase();
      let name = current.files[0].name;
      let size = (current.files[0].size / 1024 / 1024).toFixed(2);
      if (extensions.indexOf(extension) > -1) {
        let reader = new FileReader();
        let icono = FileSearch;
        reader.onload = function (e) {
          switch (extension) {
            case 'pdf':
              icono = PdfFile;
              break;
            case 'doc':
              icono = DocxFile;
              break;
            case 'docx':
              icono = DocxFile;
              break;
            case 'xls':
              icono = ExcelFile;
              break;
            case 'xlsx':
              icono = ExcelFile;
              break;
            case 'jpg':
              icono = JpgFile;
              break;
            case 'jpeg':
              icono = JpgFile;
              break;
            case 'png':
              icono = PngFile;
              break;
            default:
              break;
          }
          setRows(
            rows.map((r) =>
              r.id === id
                ? {
                    ...r,
                    icon: icono,
                    label: name,
                    cancel: true,
                    size,
                  }
                : r
            )
          );
          let find = rows.find((x) => x.id === id);
          if (find) {
            let tactual = totalSize - parseFloat(find.size);
            setTotalSize(tactual + parseFloat(size));
          } else {
            setTotalSize(totalSize + parseFloat(size));
          }
          setFiles([...files, { file: current.files[0], id: id }]);
          onSelectedFile([...files, { file: current.files[0], id: id }]);
        };

        reader.readAsDataURL(current.files[0]);
      } else {
        mensajeMini('Seleccione un archivo valido');
      }
    }
  };

  const handleAgregarFila = () => {
    setRows([
      ...rows,
      {
        id: correlativo + 1,
        icon: FileSearch,
        label: 'Subir documento',
        cancel: false,
        size: 0,
      },
    ]);
    setNumFiles(numFiles + 1);
    setCorrelativo(correlativo + 1);
  };

  const handleDeleteFile = (id) => {
    const currents = rows.filter((x) => x.id !== id);
    const filesCurrents = files.filter((x) => x.id !== id);
    const eliminar = rows.find((x) => x.id === id);

    setRows(currents);
    setFiles(filesCurrents);
    setNumFiles(numFiles - 1);
    setTotalSize(totalSize - parseFloat(eliminar.size));
    onSelectedFile(filesCurrents);
  };

  let filas = rows.map((r) => {
    return (
      <div key={r.id} className="op-boton-upload">
        <div
          className="icon"
          style={{
            backgroundImage: `url(${r.icon})`,
          }}
        />
        <span>{r.label}</span>
        <input type="file" onChange={(e) => handleChangeFile(e, r.id)} />
        {r.cancel && (
          <>
            <div className="delete">
              <CancelPresentationIcon
                color="secondary"
                onClick={() => handleDeleteFile(r.id)}
              />
            </div>
            <span
              className="size"
              style={{ color: `${r.size > 1 ? 'orange' : 'green'}` }}
            >
              {`${r.size}MB`}
            </span>
          </>
        )}
      </div>
    );
  });

  return (
    <div className="op-form-group op-file-upload">
      <div className="title">{label}</div>
      {`Archivos permitidos: ${extensions.toString()}`}
      <div className="op-content-files">{filas}</div>
      {numFiles < maxFiles && (
        <Boton
          className="op-dinamic"
          icon={() => <AddPhotoAlternateIcon />}
          onClick={() => handleAgregarFila()}
          style={{ '--button-color': '#ff4d0d' }}
        >
          Agregar nuevo
        </Boton>
      )}
      {totalSize > 2 && (
        <span className="total">{`Los archivos no debe exceder de 2MB (Los archivos no se guardaran) >>> Tamaño actual ${totalSize}MB`}</span>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onSelectedFile: Proptypes.func.isRequired,
  label: Proptypes.string,
  maxFiles: Proptypes.number,
  extensions: Proptypes.arrayOf(
    Proptypes.oneOf(['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'])
  ),
};

FileUpload.defaultProps = {
  maxFiles: 1,
  extensions: ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'],
};

export default FileUpload;
