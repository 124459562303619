import React from 'react';
import { connect } from 'react-redux';

import Icon from '@material-ui/core/Icon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import {
  eliminarHeaderAction,
  seleccionarHeaderAction,
} from '../../redux/actions/app.action';

const HeaderItem = (props) => {
  return (
    <div
      className={`op-header-item${
        props.selected
          ? props.selected.mm === props.id
            ? ' selected'
            : ''
          : ''
      }`}
    >
      {props.icon && (
        <Icon
          style={{
            color: `${
              props.selected && props.selected.mm === props.id
                ? 'whitesmoke'
                : '#949494e2'
            }`,
          }}
        >
          {props.icon}
        </Icon>
      )}
      <label onClick={() => props.seleccionarHeaderAction(props.id)}>
        {props.label}
      </label>
      <HighlightOffIcon
        onClick={() => {
          props.eliminarHeaderAction(props.id);
        }}
        fontSize="small"
        title="cerrar"
        style={{
          cursor: 'pointer',
          color: `${
            props.selected && props.selected.mm === props.id
              ? 'tomato'
              : '#9b4444'
          }`,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.app.selected,
  };
};

export default connect(mapStateToProps, {
  eliminarHeaderAction,
  seleccionarHeaderAction,
})(HeaderItem);
